import React, { useEffect, useState, useCallback, useRef, FC } from 'react';
import isEmpty from '../../Utilities/is_empty';
import { profileTranslations } from '../../Translations/profileTranslations';
import { useSelector, useDispatch } from 'react-redux';
import { userSignup } from '../../Actions/userActions';
import ButtonSpinner from '../../Common/Spinners/ButtonSpinner';
import { showToastNotification } from '../../Utilities/showToastNotification';
// import { Props } from 'react-select';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { UserSignup, UserSignupInitialPayload, UserSignupPayload } from '../../Interfaces/PayloadsAndResponses/Users';
import { AddressChangeHandler } from '../../Pages/AccountDetail/Forms/AddLocationForm';
import { NewAddressData } from '../../Pages/AccountDetail/Modals/AddNewLocationModal';
import { initialAddressData } from '../../Pages/AccountDetail/Modals/AddNewDepartmentModal';
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SignupForm from './SignupForm';
// import { emailPattern, phoneNumberPattern } from '../../Utilities/validationPatterns';
import Modal from 'react-modal';
// import ModalHeader from '../../Common/ModalHeader';
import { hasValueInKey } from '../../Utilities/functions';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { PhoneFormatter } from '../../Utilities/PhoneFormatter';
// import { PlaceCartOrderPayload } from '../../Interfaces/PayloadsAndResponses/Cart';
// import{ placeCartOrder } from '../../Actions/cartActions';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '500px',
    width: '90vw',
    padding: '0',
    background: 'none',
    border: 'none',
    borderRadius: '18px'
  }
};

export type SignUpDataType = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

const initialData: SignUpDataType = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  phoneNumber: '',
  password: '',
  confirmPassword: ''
};

let initialGoogleData: SignUpDataType = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  phoneNumber: '',
  password: '',
  confirmPassword: ''
};

export type SignUpErrorType = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  addressLine: string
}

type SignupWidgetProps = {
  isMarketPlaceSignUp?: boolean;
  isCartSignUp?:boolean;
  isOpenSignup?: boolean;
  cartDisplayHandling?: ((page: any) => void) | undefined;
  signupLoginRedirect?: ((page: any) => void) | undefined;
  // onRef?: (openModalRef: (() => void) | null) => void;
  // cartOrderPayload?: PlaceCartOrderPayload;
  isOpenModal: boolean;
  closeModal?: () => void;
}



//const SignUpWidget = forwardRef(({}: Props, ref) => {

const SignUpWidget: FC<SignupWidgetProps> = ({ isMarketPlaceSignUp, isCartSignUp, cartDisplayHandling, signupLoginRedirect, isOpenModal, closeModal }) => {
  const [signupLoading, setSignupLoading] = useState<boolean>(false)
  const i18n = useSelector((state: ReduxRootState) => state?.i18n?.i18n);
  const dispatch: any = useDispatch();


  const [addressData, setAddressData] = useState<NewAddressData>(initialAddressData);
  const [phone, setPhone] = useState<any>('')
  const { addressLine, longitude, latitude, city, state, zipCode, timezone, streetNumber, street } = addressData;
  const validAddressRef: any = useRef()
  const validPhoneRef: any = useRef()

  const { executeRecaptcha } = useGoogleReCaptcha();
  // const [token, setToken] = useState('');
  const tokenRef = useRef('');
  // const [noOfVerifications, setNoOfVerifications] = useState(0);
  // const [dynamicAction, setDynamicAction] = useState('homepage');
  // const [actionToChange, setActionToChange] = useState('');

  const googleSignUpUserData = JSON.parse(localStorage.getItem('signup-in-progress') || '{}')

  const [backendResponse, setBackendResponse] = useState<UserSignup>();
  const [continueWithCompany, setContinueWithCompany] = useState(false);
  const [googleSignUp, setGoogleSignUp] = useState('')
  const [googleSignUpStatus, setGoogleSignUpStatus] = useState(false)
  const [googleFormData, setGoogleFormData] = useState({
    firstName: googleSignUpUserData?.first_name,
    lastName: googleSignUpUserData?.last_name,
    email: googleSignUpUserData?.email,
  })


  const history = useHistory();

  // const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const cartOrderPayloadFromRedux = useSelector((state: ReduxRootState) => state?.cart?.cart_order_payload);
  const { register, formState: { errors }, handleSubmit, getValues } = useForm<SignUpDataType>({
    defaultValues: googleSignUpStatus ? initialGoogleData : initialData,
    mode: "onSubmit",
    reValidateMode: "onChange"
  });


  useEffect(() => {

    const googleSignUpStatus = JSON.parse(localStorage.getItem('signup-in-progress') || '{}')
    setGoogleSignUp(googleSignUpStatus)

    if(googleFormData){

    }

    if (!isEmpty(googleSignUpStatus)) {
      initialGoogleData = {
        ...initialGoogleData,
        firstName: googleSignUpStatus?.first_name,
        lastName: googleSignUpStatus?.last_name,
        email: googleSignUpStatus?.email,
      }
      setGoogleFormData({
        firstName: googleSignUpStatus?.first_name,
        lastName: googleSignUpStatus?.last_name,
        email: googleSignUpStatus?.email,
      }
      )
      setContinueWithCompany(true)
      setGoogleSignUpStatus(true)

    }
    // eslint-disable-next-line
  }, [continueWithCompany, googleSignUpStatus])

  // Start recaptcha
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('yourAction');
    // setToken(token);
    tokenRef.current = token
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // End recaptcha

  useEffect(() => {
    profileTranslations();
    var i = Math.floor((Math.random() * 9) + 1);
    let bannerElement: HTMLElement | null = document?.getElementById('banner');
    if (bannerElement)
      bannerElement.style.backgroundImage = 'url(/imgs/' + i + '.jpg)';
  }, [])

  const handleContinueWithCompany = (continueClickStatus: boolean) => {
    setContinueWithCompany(continueClickStatus)
  }

  const signupSuccessHandler = (response: UserSignup, uid?: any) => {
    setSignupLoading(false);
    setBackendResponse(response)
    if (response?.success === true) {
      // showToastNotification(response?.message + '.', 'Oops!', true, 'top-left');
      if (isEmpty(response?.message)) {
        setContinueWithCompany(true)
      }
    } else if (response?.success === false) {
      showToastNotification(response?.message + '.', 'Oops!', true, 'top-left');
    } else {
      if (uid) {

        if (isCartSignUp && cartOrderPayloadFromRedux) {
          history.push({ pathname: `/menu/${cartOrderPayloadFromRedux?.runningmenu.delivery_at}/` });
          // dispatch(placeCartOrder?.(cartOrderPayload as PlaceCartOrderPayload));
          // window.location.href = `/menu/${cartOrderPayload?.runningmenu.delivery_at}`;
        } else {
          let dateParam = dayjs().format('M-D-YYYY'); // If not on Menu Page then Set to Today Date.
          let dateForMarketplace = dayjs(dateParam, 'M-D-YYYY') <= dayjs() ? dayjs().add(1, 'day').format('M-D-YYYY') : dateParam;
          history.push({ pathname: `/menu/${dateForMarketplace}/` });
        }

        //removed isMarketPlaceSignUp check coz after signup it should be redirected to logged-in marketplace
        // if (isMarketPlaceSignUp){
        //   let dateParam = dayjs().format('M-D-YYYY'); // If not on Menu Page then Set to Today Date.
        //   let dateForMarketplace = dayjs(dateParam, 'M-D-YYYY') <= dayjs() ? dayjs().add(1, 'day').format('M-D-YYYY') : dateParam;
        //   history.push({ pathname: `/menu/${dateForMarketplace}/` });
        //   closeModal()
        // }


        // let dateParam = dayjs().format('M-D-YYYY'); // If not on Menu Page then Set to Today Date.
        // let dateForMarketplace = dayjs(dateParam, 'M-D-YYYY') <= dayjs() ? dayjs().add(1, 'day').format('M-D-YYYY') : dateParam;
        // history.push({ pathname: `/menu/${dateForMarketplace}/` });

        // for selfsignup 
        // window.location.reload()
        // showToastNotification(
        //   response?.message,
        //   'Thank you!',
        //   false,
        //   'top-left');
      }
    }
    // Removed because showing it multiple times
    // else {
    //   showToastNotification(response?.message + '.', 'Oops!', true, 'top-left');
    // }
  }


  // const submittHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   SetBeforeSubmit(false)

  //   const UserSignupPayload: UserSignupPayload = {
  //     first_name: firstName,
  //     last_name: lastName,
  //     email: email,
  //     password: password,
  //     // continue_with_company: continueWithCompany,
  //     // self_signup_form: true,
  //     company_name: companyName,
  //     phone_number: phoneNumber,
  //     address_attributes: {
  //       address_line: addressLine,
  //       city: city,
  //       state: state,
  //       zip: zipCode,
  //       longitude: longitude,
  //       latitude: latitude
  //     }
  //   }

  //   handleReCaptchaVerify();

  //   let hasError = validate();
  //   if (!hasValueInKey(hasError) && tokenRef) {
  //     setSignupLoading(true);
  //     dispatch(userSignup(UserSignupPayload, signupSuccessHandler))
  //     // setFirstShown(true);
  //   }
  // };

  // const handleChange = ( e : React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement>) => {
  //   let { target: { name, value } } = e;
  //   // let newValue = value

  //   setErrorAlert(!errorAlert)
  //   if (name === 'phoneNumber') {
  //     let temp: (RegExpMatchArray | null) = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  //     if(temp) {
  //       value = !temp[2] ? temp[1] : + temp[1] + '-' + temp[2] + (temp[3] ? '-' + temp[3] : '')
  //     }
  //     if(phoneNumberPattern.test(value)) {
  //       setErrors({..._errors, [name]:'Please enter phone'})
  //     } else {
  //       setErrors({..._errors, [name]:'Please use this format: XXX-XXX-XXXX'})
  //     }
  //     setFormData({ ...formData, [name]: value })

  //   } else if (name === 'email') {
  //     if(!value) {
  //       setErrors({..._errors, [name]:'Email is required'})
  //     } else if (emailPattern.test(email)){
  //       setErrors({..._errors, [name]:'Please enter a valid email'})
  //     } else {
  //       // setErrors({..._errors, [name]:''})
  //       setFormData({ ...formData, [name]: value })
  //     }

  //   } else if(name ==='password' || name ==='confirmPassword') {
  //     setFormData({ ...formData, [name]: value })
  //     handleValidation(e)    
  //   }
  //   else {
  //   setFormData({ ...formData, [name]: value })
  //   setErrors({..._errors, [name]:''})
  //   }

  // };



  const handle: any = (phone: any) => {
    if (!isEmpty(phone)) {
      setPhone(phone)
      validPhoneRef.current = true;
    } else {
      validPhoneRef.current = false;
    }
    phoneValidate()
  }
  const invalidPhone = () => {
    validPhoneRef.current = false
  }
  const phoneValidate = () => {
    if (phone.match(/^[0-9]{10}$/)) {
      validPhoneRef.current = true;
    } else {
      validPhoneRef.current = false
    }
  }


  const handleAddress: AddressChangeHandler = (address) => {
    if (!isEmpty(address)) {
      validAddressRef.current = true;
      setAddressData({ ...addressData, ...address });
    } else {
      validAddressRef.current = false;
    }

  };




  const signUpFormValidation = () => {
    validateAddress()
    phoneValidate()
  }
  const validateAddress = () => {
    if (isEmpty(addressLine) || addressLine === '') {
      validAddressRef.current = false;
    } else {
      validAddressRef.current = true;
    }
  }

  const onSubmit = (data: SignUpDataType) => {

    const { firstName, lastName, email, companyName, password } = getValues();
    // setSubmitValue(data);

    const localStorageAddress = JSON.parse(localStorage.getItem('address') || '{}');
    const googleSignUpUserData = JSON.parse(localStorage.getItem('signup-in-progress') || '{}')
    const addressForPublicSignUp: any = {
      address_attributes: {
        address_line: localStorageAddress.address_line,
        city: localStorageAddress.city,
        latitude: localStorageAddress.latitude,
        longitude: localStorageAddress.longitude,
        state: localStorageAddress.state,
        time_zone: localStorageAddress.time_zone,
        street_number: localStorageAddress.street_number,
        street: localStorageAddress.street
      }
    }
    const UserSignupPayload: UserSignupPayload = isEmpty(googleSignUpUserData) ? {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      continue_with_company: continueWithCompany,
      // self_signup_form: true,
      company_name: companyName,
      phone_number: PhoneFormatter(phone),
      google_signup: googleSignUpStatus,
      address_attributes: {
        address_line: addressLine,
        city: city,
        state: state,
        zip: zipCode,
        longitude: longitude,
        latitude: latitude,
        time_zone: timezone,
        street_number: streetNumber,
        street: street
      }
    } : {
      first_name: googleSignUpUserData?.first_name,
      last_name: googleSignUpUserData?.last_name,
      email: googleSignUpUserData?.email,
      password: password,
      continue_with_company: continueWithCompany,
      // self_signup_form: true,
      company_name: companyName,
      phone_number: PhoneFormatter(phone),
      google_signup: googleSignUpStatus,
      address_attributes: {
        address_line: addressLine,
        city: city,
        state: state,
        zip: zipCode,
        longitude: longitude,
        latitude: latitude,
        time_zone: timezone,
        street_number: streetNumber,
        street: street
      }
    }
    const UserSignupInitialPayload: UserSignupInitialPayload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      continue_with_company: continueWithCompany
    }
    const UserSignupPayloadForOutsideUser: UserSignupPayload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      continue_with_company: continueWithCompany,
      password: password,
      company_name: companyName,
      phone_number: PhoneFormatter(phone),
      address_attributes: addressForPublicSignUp.address_attributes,
      google_signup: googleSignUpStatus,
    }

    handleReCaptchaVerify();

    if ((isMarketPlaceSignUp || isCartSignUp) && !hasValueInKey(errors) && validPhoneRef.current) {
      setSignupLoading(true);
      if (cartOrderPayloadFromRedux && cartOrderPayloadFromRedux.runningmenu?.orders_attributes?.length > 0) {
        dispatch(userSignup(UserSignupPayloadForOutsideUser, signupSuccessHandler, cartOrderPayloadFromRedux))
      } else {
        dispatch(userSignup(UserSignupPayloadForOutsideUser, signupSuccessHandler))
      }
    } else if (!hasValueInKey(errors) && tokenRef) {
      if (continueWithCompany) {
        if (validAddressRef.current && validPhoneRef.current) {
          setSignupLoading(true);
         dispatch(userSignup(UserSignupPayload, signupSuccessHandler))
        }
      } else {
        setSignupLoading(true);
       dispatch(userSignup(UserSignupInitialPayload, signupSuccessHandler))
      }
      // setFirstShown(true);
    }
  };

// useEffect(() => {
//   if(onRef) {
//     onRef(openModal);
//     return () => {
//       onRef(null);
//     }
//   }
// }, [onRef])
// const openModal = () => {
//   setModalIsOpen(true)
// }

// const closeModal = () => {
//   setModalIsOpen(false)
// };

  return (
    isMarketPlaceSignUp ?
      <Modal
        isOpen={isOpenModal}
        // isOpen={isOpenSignup ?? false}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className='modal-dialog'>
          <div className='modal-content px-4 py-32'>
            {/* Modal Header */}
            <div className={` border-0 bg-white d-flex justify-content-between align-items-center`}>
              <span></span>
              <h4 id='modal-title' className='modal-title fooditem-text-capitalize text-case order-popup-title order-popup-header-title'>
                {i18n ? i18n.t('Sign up') : 'Sign up'}
              </h4>
              <button type='button' onClick={closeModal} className='close m-0 p-0 pr-2 modal-close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>
                  <i className="fas fa-times"></i>
                </span>
              </button>
            </div>
            {/* Modal Body */}
            <div
              // style={{ height: `calc(95vh - 290px)`, overflowX: 'hidden',overflowY: 'scroll' }}
              className='position-relative'
              id='order-popup-body'
            >
              <form onSubmit={handleSubmit(onSubmit, signUpFormValidation)}>
              <SignupForm
                  handleAddress={handleAddress}
                  errors={errors}
                  validAddressRef={validAddressRef.current}
                  validPhoneRef={validPhoneRef.current}
                  invalidPhone={invalidPhone}
                  handle={handle}
                  googleSignUp={googleSignUp}
                  backendResponse={backendResponse}
                  register={register}
                  getValues={getValues}
                  isMarketPlaceSignUp={isMarketPlaceSignUp}
                handleContinueWithCompany={handleContinueWithCompany}
                />
                <div className='px-6'>
                  <button type='submit' className='btn btn-lg btn-danger btn-block mt-3 border'>
                    {signupLoading ? <ButtonSpinner /> : i18n?.t('Sign up')}
                  </button>
                  <p className='fs-18 fw-normal text-center text-gray-200 m-0 mt-3' onClick={() => { closeModal?.(); signupLoginRedirect?.('signup') }}>Already have an account ? <span className='text-danger cursor-pointer'>Log In</span></p>
                </div>
              </form>

            </div>
          </div>
        </div>
      </Modal>
      :
      <>
        {!isCartSignUp ? <div className='container-fluid h-100 '>
          <div className='row h-100 justify-content-center'>
            <div className='col-12 col-md-4 px-3'>
              <div className='d-flex justify-content-center align-items-center mt-5'>
                <img src='/imgs/chowmill_icon.svg' className='mr-3' alt='' style={{ height: 60 }} />
                <h1 className='m-0 logo-chowmill-t' style={{ color: '#303030', fontSize: 40 }}>Chowmill</h1>
              </div>
              <h2 className='mt-3 text-center'>{i18n?.t('Signup')}</h2>
              <h5 className='signup_subtext text-center'>Already registered? &nbsp;
                <Link to='/signin' >{i18n && i18n.t('Login')}</Link> </h5>
              <form onSubmit={handleSubmit(onSubmit, signUpFormValidation)}>
                <SignupForm
                  // handleChange={handleChange}
                  handleAddress={handleAddress}
                  errors={errors}
                  validAddressRef={validAddressRef.current}
                  validPhoneRef={validPhoneRef.current}
                  invalidPhone={invalidPhone}
                  handle={handle}
                googleSignUp={googleSignUp}
                  // value={formData}
                  // valid = {validate}
                  backendResponse={backendResponse}
                  // handleValidation={handleValidation}
                  register={register}
                  getValues={getValues}
                handleContinueWithCompany={handleContinueWithCompany}
                />
                <div className="mx-2">
                  <button
                    type='submit'
                    className='btn btn-lg btn-danger btn-block mt-3'
                  // disabled={enableSignUpButton()}
                  // onClick={submittHandler}
                >
                  {signupLoading ? <ButtonSpinner /> : i18n?.t('Sign up')}
                </button>
             </div>

              </form>
              {/* <div>
              {/* <div>
                <p>Current ReCaptcha action: {dynamicAction}</p>
                <input type="text" onChange={handleTextChange} value={actionToChange} />
                <button type='button' onClick={handleCommitAction}>Change action</button>
              </div> */} {/*
              <br />
              <button type='button' onClick={handleReCaptchaVerify}>Run verify</button>
              <br />
              {token && <p>Token: {token}</p>}
              <p> No of verifications: {noOfVerifications}</p>
            </div> */}
              {/* <div className="container px-3">
              <button
                type='submit'
                className='btn btn-lg btn-danger btn-block mt-3'
                // disabled={!enableSignUpButton()}
                onClick={submittHandler}
              >
                {signupLoading ? <ButtonSpinner /> : i18n?.t('Signup')}
              </button>
           </div> */}
              <p className='mt-5 mb-3 text-muted text-center'> {i18n && (i18n.t('© Copyright') + new Date().getFullYear() + i18n.t('Chowmill'))}</p>
            </div>
            <div className='col-md-8 d-none d-lg-block login_banner' id='banner'>
            </div>
          </div>
        </div>
          :
          <>
            <form onSubmit={handleSubmit(onSubmit, signUpFormValidation)}>
              <SignupForm
                handleAddress={handleAddress}
                errors={errors}
                validAddressRef={validAddressRef.current}
                validPhoneRef={validPhoneRef.current}
                invalidPhone={invalidPhone}
                handle={handle}
                backendResponse={backendResponse}
                register={register}
                getValues={getValues}
                isCartSignUp={isCartSignUp}
                handleContinueWithCompany={handleContinueWithCompany}
              />
              <div className='px-6'>
                <button type='submit' className='btn btn-lg btn-danger btn-block mt-3 border'>
                  {signupLoading ? <ButtonSpinner /> : i18n?.t('Sign up')}
                </button>
                <p className='fs-18 fw-normal text-center text-gray-200 m-0 mt-3' onClick={() => {cartDisplayHandling?.('login')}}>Already have an account ? <span className='text-danger cursor-pointer'>Log In</span></p>
              </div>
            </form>
          </>
        }
      </>
  );
};

const signUpWidgetWrapper: FC<SignupWidgetProps> = ({ 
  isMarketPlaceSignUp,
  isCartSignUp,
  cartDisplayHandling,
  signupLoginRedirect,
  // onRef,
  // cartOrderPayload,
  isOpenModal,
  closeModal
}) => (

  <GoogleReCaptchaProvider
    useEnterprise
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string ?? ''}
    scriptProps={{ async: true, defer: true, appendTo: 'body' }}
  >
    <SignUpWidget 
      isMarketPlaceSignUp={isMarketPlaceSignUp}
      isCartSignUp={isCartSignUp}
      cartDisplayHandling={cartDisplayHandling}
      signupLoginRedirect={signupLoginRedirect}
      // onRef={onRef}
      // cartOrderPayload={cartOrderPayload}
      isOpenModal={isOpenModal}
      closeModal={closeModal}
    />
  </GoogleReCaptchaProvider>
);

export default signUpWidgetWrapper
