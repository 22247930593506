import axios from 'axios';
import { GET_COMPANY_USERS, LOADING, DELETE_USER, REACTIVATE, GET_ANNOUNCEMENTS, GET_COMPANY_TAGS, GET_COMPANY_TIMEZONES, GET_COMPANY_ADDRESSES_FOR_USER, GET_USER_TYPES, GET_FILTER_COUNT_DELETED, GET_FILTER_COUNT_INVITED, GET_FILTER_COUNT_ADMIN, GET_FILTER_COUNT_MANAGER, GET_FILTER_COUNT_UNSUBSIZIZED, GET_FILTER_COUNT_REGULAR_USER } from './types';
import { getError } from './errorActions';
import { showToastNotification } from '../Utilities/showToastNotification';
import { getloggedInUser } from '../Utilities/getloggedInUser';
import isEmpty from '../Utilities/is_empty';
import { AppThunkAction } from '../store';
import { UpdateUser, UserSignup } from '../Interfaces/PayloadsAndResponses/Users';
import { setCurrentUser } from './authActions';
import { UserHeaders } from '../Interfaces/PayloadsAndResponses/Auth';
import { placeCartOrder } from './cartActions';
import { PlaceCartOrderPayload } from '../Interfaces/PayloadsAndResponses/Cart';
const queryString = require('query-string');

// Get Company Users
export const getCompanyUsers = (queryParams: string): AppThunkAction<{ type: typeof GET_COMPANY_USERS | typeof GET_COMPANY_TAGS | typeof GET_COMPANY_TIMEZONES| typeof GET_COMPANY_ADDRESSES_FOR_USER | typeof GET_USER_TYPES|  typeof GET_FILTER_COUNT_DELETED| typeof GET_FILTER_COUNT_INVITED| typeof GET_FILTER_COUNT_ADMIN| typeof GET_FILTER_COUNT_MANAGER| typeof GET_FILTER_COUNT_UNSUBSIZIZED| typeof GET_FILTER_COUNT_REGULAR_USER|  typeof LOADING }> => dispatch => {
  dispatch(setLoading());

  axios
    .get(`/users${queryParams ? queryParams : ''}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      return (
        dispatch({
          type: GET_COMPANY_USERS,
          payload: res.data
        }),
        dispatch({
          type: GET_COMPANY_TAGS,
          payload: res.data.company_tags
        }),
        dispatch({
          type: GET_COMPANY_TIMEZONES,
          payload: res.data.time_zones
        }),
        dispatch({
          type: GET_COMPANY_ADDRESSES_FOR_USER,
          payload: res.data.company_addresses
        }),
        dispatch({
          type: GET_USER_TYPES,
          payload: res.data.user_types
        }),
        dispatch({
          type: GET_FILTER_COUNT_DELETED,
          payload: res.data.deleted_users_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_INVITED,
          payload: res.data.invited_users_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_ADMIN,
          payload: res.data.admin_users_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_MANAGER,
          payload: res.data.managers_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_UNSUBSIZIZED,
          payload: res.data.unsubsidized_users_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_REGULAR_USER,
          payload: res.data.company_users_count
        })
      )}
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getTokenizedFilteredCompanyUsers = (queryParams: string, userTypeFilterValues: number[], deletedUserFilterValue: string | null, isInvitedUserFilterValue: boolean | null, page: number): AppThunkAction<{ type: typeof GET_COMPANY_USERS | typeof GET_FILTER_COUNT_DELETED| typeof GET_FILTER_COUNT_INVITED| typeof GET_FILTER_COUNT_ADMIN| typeof GET_FILTER_COUNT_MANAGER| typeof GET_FILTER_COUNT_UNSUBSIZIZED| typeof GET_FILTER_COUNT_REGULAR_USER| typeof LOADING }> => dispatch => {
  dispatch(setLoading());

  //const finalizedQueryParams = `?query=${ queryParams ? queryParams : ''}${queryParamsForFilters ? "&"+queryParamsForFilters : ''}`
  axios
    .get(`/users`,

      {
        params: { 'query': queryParams,
        'user_types[]': userTypeFilterValues,
        'status': deletedUserFilterValue,
        'invited_user': isInvitedUserFilterValue,
        'page': page ?? 1
        },
        paramsSerializer: function (params) {
          return queryString.stringify(params, { indices: false, skipNull: true, skipEmptyString: true })
        },
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      }
    )
    .then(res => {
      return (
        dispatch({
          type: GET_COMPANY_USERS,
          payload: res.data
        }),
        dispatch({
          type: GET_FILTER_COUNT_DELETED,
          payload: res.data.deleted_users_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_INVITED,
          payload: res.data.invited_users_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_ADMIN,
          payload: res.data.admin_users_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_MANAGER,
          payload: res.data.managers_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_UNSUBSIZIZED,
          payload: res.data.unsubsidized_users_count
        }),
        dispatch({
          type: GET_FILTER_COUNT_REGULAR_USER,
          payload: res.data.company_users_count
        })
      )
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getFilteredCompanyUsers = (queryParams: string): AppThunkAction<{ type: typeof GET_COMPANY_USERS | typeof LOADING }> => dispatch => {
  dispatch(setLoading());
  axios
    .get(`/users${queryParams ? '?' + queryParams : ''}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_COMPANY_USERS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const userSignup = (userData: any, signupSuccessHandler: (response: UserSignup, uid?: any) => void, cartOrderPayload?: PlaceCartOrderPayload): AppThunkAction => dispatch => {
  axios
    .post(`/user_requests`, userData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {

      if (res?.headers?.uid) {
        const headers = res?.headers;
        const { data } = res?.data as any ?? {};
        const { user, company } = data ?? {};
        try {
          const newHeaders: UserHeaders = {
            'access-token': '',
            client: '',
            expiry: '',
            'token-type': '',
            uid: '',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': 'en/US'
          };
          Object.keys(headers).forEach(function (key) {
            switch (key) {
              case 'access-token':
              case 'client':
              case 'expiry':
              case 'token-type':
              case 'uid':
                newHeaders[key] = headers[key];
                break;
              default:
                break;
            }
          });
          localStorage.setItem('headers', JSON.stringify(newHeaders));
          if (user?.first_name) {
            user.first_name = user.first_name?.trim();
          }
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('company', JSON.stringify(company));
          localStorage?.removeItem?.('signup-in-progress');
        } catch (e) {
          console.log('error', e);
        }
        // Set current User
        dispatch(setCurrentUser(user, headers as UserHeaders));
        // Remove address from local storage in case of signup
        // const { loggedInUser } = getloggedInUser()
        // const localAddress = JSON.parse(localStorage.getItem('address') || '{}');

        // const modifiedAddress = {
        //   address_line: localAddress?.addressLine,
        //   address_name: localAddress?.formatted_address,
        //   suite_no: localAddress?.suite_no,
        //   longitude: localAddress?.longitude,
        //   latitude: localAddress?.latitude,
        //   time_zone: localAddress?.time_zone,
        //   delivery_instructions: localAddress?.delivery_instructions,
        //   street_number: localAddress?.street_number,
        //   street: localAddress?.street,
        //   city: localAddress?.city,
        //   state: localAddress?.state,
        //   zip: localAddress?.zip
        // }
        // const runningMenuWithAddressID = { ...cartOrderPayload?.runningmenu, address_id: '' }
        // const cartOrderspayloadWithAddressId = { ...cartOrderPayload, company: { addresses_active_attributes: [modifiedAddress] } }

        cartOrderPayload && dispatch(placeCartOrder?.(cartOrderPayload as PlaceCartOrderPayload, headers as UserHeaders));
        // localStorage?.removeItem?.('address');
      }
      cartOrderPayload ?
      setTimeout(() => {
        signupSuccessHandler?.(res.data as UserSignup, res?.headers?.uid)
      }, 10000)
      : signupSuccessHandler?.(res.data as UserSignup, res?.headers?.uid)

    })

    .catch(err =>
      dispatch(getError(err))
    );
};

export const resendVerifyEmail = (): AppThunkAction<any> => dispatch => {
  axios
    .get('/users/resend_verify',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      showToastNotification(res?.data?.message, 'Success!');
    }
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const editUser = (id: number, userData: UpdateUser, sidebarClose: () => void): AppThunkAction => dispatch => {
  axios
    .put(`/users/${id}`, userData,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      sidebarClose();
      window.location.reload()
      showToastNotification( res.data.message, 'Success!');
    })
    .catch(err => {
      dispatch(getError(err));
    });
};


// Resend Invitation
export const resendInvitation = (id: number): AppThunkAction => dispatch => {
  axios
    .post(`/users/${id}/send_invite`, '',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => showToastNotification(res.data.message, 'Success'))
    .catch(err => console.log(err));
}
// Ractivate User
export const reactivateUser = (id: number): AppThunkAction => dispatch => {
  axios
    .post(`/users/${id}/active`, '',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      return (
        dispatch({
          type: REACTIVATE,
          payload: id
        }),
        showToastNotification(res.data.message, 'Success')
      )
    })
    .catch(err => console.log(err));
}
// Delete Company User
export const deleteSingleUser = (id: number): AppThunkAction => dispatch => {
  axios
    .delete(`/users/${id}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => dispatch({
      type: DELETE_USER,
      payload: id
    })
    )
    .catch(err => err);
}

export const getFutureDelivery = (forMenuTab = true) => {
  const { loggedInUser } = getloggedInUser()
  if (isEmpty(loggedInUser?.address_id)) {
    // Donot call API if user Address is not assigned Because it throws error from Backend otherwise
    return null
  } else {
    return axios
      .get(`/companies/future_meeting${forMenuTab ? '?menu=true' : ''}`,
        {
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        });
  }
};

export const getAnnouncements = (): AppThunkAction<{ type: typeof GET_ANNOUNCEMENTS }> => dispatch => {
  axios
    .get(`/announcements`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then((res) => {
      dispatch({
        type: GET_ANNOUNCEMENTS,
        payload: res.data
      })
    })
    .catch((err) =>
      dispatch(getError(err))
    );
};
// Status Loading
export const setLoading: () => { type: typeof LOADING } = () => {
  return {
    type: LOADING
  };
}
