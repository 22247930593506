// // Packages
// import React, { Component, CSSProperties } from 'react';
// import dayjs from 'dayjs';
// import { connect } from 'react-redux';
// import ReactToPrint from 'react-to-print';
// import Skeleton from 'react-loading-skeleton';
// import { WithTranslation } from 'react-i18next';
// import { RouteComponentProps, withRouter } from 'react-router-dom';
// import Modal from 'react-modal';
// // React JSX Components
// import Spinner from '../../Common/Spinners/Spinner';
// import TableHeader, { TableColumn } from '../../Common/TableHeader';
// import { DeliveryOrdersSidebarMeeting } from '../../Common/Popups/DeliveryOrdersSidebar';
// import DeliveryOrderDetailsLoading from '../../Common/Loadings/OrderHistoryPage/DeliveryOrderDetailsLoading';
// // Redux Dispatch Actions
// import { sendOrderDetailsEmail } from '../../Actions/emailActions';
// import { getOrderDetails, cancelOrder, getSelfSignUpOrderDetails, updateSelfSignUpDeliveryDetails } from '../../Actions/orderActions';
// // Utilities
// import store from '../../store';
// import isEmpty from '../../Utilities/is_empty';
// // import isEmpty from '../../reducers/is_empty';
// import { ArrayOfLength } from '../../Utilities/ArraysUtilities';
// import { EMPTY_DELIVERY_ORDERS_DETAILS } from '../../Actions/types';
// import { UserType } from '../../Interfaces/PayloadsAndResponses/Auth';
// import { showToastNotification } from '../../Utilities/showToastNotification';
// import { GetloggedInUser, getloggedInUser } from '../../Utilities/getloggedInUser';
// import { orderDetailsTranslations } from '../../Translations/orderDetailsTranslations'
// // Types
// import { cancel_order_loading_key_prefix } from '../../Reducers/orderReducer';
// import { DeliveryAllOrders, OrderStatus } from '../../Interfaces/PayloadsAndResponses/Orders';
// import { EmailsDetails, OrderDetails, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// // CSS
// import './sidebar.css'
// import ReactTooltip from 'react-tooltip';
// import { cancelCartOrder } from '../../Actions/cartActions';
// import Carousel from 'react-multi-carousel';

// const carousel_responsive = {
//   tablet: {
//     breakpoint: { max: 768, min: 464 },
//     items: 1,
//     slidesToSlide: 1, 
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//     slidesToSlide: 1, 
//   },
// };

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '692px',
//     padding: '0',
//     borderRadius: '18px'
//   }
// };

// type OrdersFilters = 'showUnordered' | 'showMyOrders' | 'showDeleted';
// type Params = { runningmenuSlug?: string, date?: string, meetingSlug?: string };
// type OrderDetailsProps = {
//   i18n: WithTranslation | null;
//   order: OrderDetails;
//   email: EmailsDetails;
//   user: GetloggedInUser;
//   isOpenSidebar: boolean;
//   closeSidebar: () => void;
//   invitedMeetingToken?: string;
//   fromMenuDetailPopup?: boolean;
//   updatePageOnOrderDelete?: () => void;
//   updateOrder?: (orderId: number) => void;
//   selectedMeeting: DeliveryOrdersSidebarMeeting;
//   sendOrderDetailsEmail: (runningmenuSlug: string) => void;
//   updateSelfSignUpDeliveryDetails: (slug: string, delivery_instructions: string, DeliveryDetailsLoaderHandler: () => void) => void;
//   getSelfSignUpOrderDetails: (slug: string) => void;
//   cancelCartOrder?: (slug: string, cancel_reason: string,deleteSucessHandler: () => void) => void;
//   getOrderDetails: (runningmenuSlug: string, shareMeetingToken?: string | undefined, queryParams?: string, canceledOrderId?: string) => void;
//   cancelOrder: (canceledOrderId: number, deliveryDate: string | undefined, shareMeetingToken: string | undefined, canceledOrderCallback: (canceledOrderId?: string) => void) => void;
// } & RouteComponentProps<Params>

// type OrderDetailsState = {
//   orders_details: OrderDetails;
//   userOwnOrders: DeliveryAllOrders[];
//   userActiveOrdersQuantity: number;
//   guestOrdersQuantity: number;
//   guestOrders: DeliveryAllOrders[];
//   unorderedQuantity: number;
//   detailsEmail: boolean;
//   showDeleted: boolean;
//   showGuestOrders: boolean;
//   showUnordered: boolean;
//   showMyOrders: boolean;
//   loadOrderSummary: boolean;
//   sortType: string | string[];
//   sortOrder: string | string[];
//   tableColumnsWidths: number[];
//   orderFilterSearchValue: string;
//   isSelectedMeetingChanged: boolean;
//   menuPageOrdersTableBodyHeight: number | string;
//   toggleDropdown: boolean;
//   deliveryInstructionsEdited: string;
//   deliveryEditable: boolean;
//   deliveryEditableLoader: boolean;
//   deliveryCancelPopUp: boolean;
//   deliveryCancelReason: string;
// }

// const orderDetailHeading: CSSProperties = {
//   color: '#999999',
//   fontWeight: 600,
//   marginBottom: 5,
// }

// class DeliveryOrderDetails extends Component<OrderDetailsProps, OrderDetailsState> {

//   state: OrderDetailsState = {
//     orders_details: {} as OrderDetails,
//     userOwnOrders: [],
//     guestOrders: [],
//     userActiveOrdersQuantity: 0,
//     guestOrdersQuantity: 0,
//     unorderedQuantity: 0,
//     detailsEmail: false,
//     showDeleted: false,
//     showUnordered: false,
//     showGuestOrders: false,
//     showMyOrders: false,
//     loadOrderSummary: true,
//     menuPageOrdersTableBodyHeight: 'unset',
//     sortType: 'user',
//     sortOrder: 'ASC',
//     orderFilterSearchValue: '',
//     tableColumnsWidths: [],
//     isSelectedMeetingChanged: false,
//     toggleDropdown: false,
//     deliveryInstructionsEdited: '',
//     deliveryEditable: false,
//     deliveryEditableLoader: false,
//     deliveryCancelPopUp: false,
//     deliveryCancelReason: ''
//   }

//   getTableBodyHeight = () => {
//     return document.body.clientHeight - ((document.getElementById('menu-detail-page-order-summary')?.clientHeight ?? 0) + 10); // 10 is added to show bottom scrollbar
//   }

//   componentDidMount() {
//     orderDetailsTranslations();
//     document.title = 'Order Details | Chowmill';
//     const menuPageOrdersTableBodyHeight = this.getTableBodyHeight();
//     this.setState({ menuPageOrdersTableBodyHeight })
//     const { selectedMeeting: { slug },
//       invitedMeetingToken,
//     } = this.props;
//     const { loggedInUserCompany } = getloggedInUser();

//     let queryParams = '?sort_order=ASC&sort_type=user';
//     // this.props.getOrderDetails(slug, invitedMeetingToken, queryParams);
//     if (loggedInUserCompany.self_signup === true) {
//       this.props.getSelfSignUpOrderDetails(slug)
//     } else {
//       this.props.getOrderDetails(slug, invitedMeetingToken, queryParams);
//     }

//     setTimeout(() => {
//       this.setState({ loadOrderSummary: false })
//     }, 2000);
//   }

//   componentDidUpdate(prevProps: OrderDetailsProps, prevState: OrderDetailsState) {
//     const { selectedMeeting,
//       order: { delivery_orders_details },
//       isOpenSidebar
//     } = this.props

//     const { selectedMeeting: prev_selectedMeeting,
//       order: { delivery_orders_details: prev_delivery_orders_details },
//       isOpenSidebar: prev_isOpenSidebar,
//     } = prevProps;
//     const { slug } = selectedMeeting;
//     const { slug: prev_meeting_slug } = prev_selectedMeeting;
//     const { showDeleted, showMyOrders, showUnordered, isSelectedMeetingChanged } = this.state;

//     if ((prev_delivery_orders_details?.orders !== delivery_orders_details?.orders) || (prevState.showDeleted !== showDeleted) || (prevState.showMyOrders !== showMyOrders) || (prevState.showUnordered !== showUnordered)) {
//       this.setState({ menuPageOrdersTableBodyHeight: this.getTableBodyHeight() });
//     }

//     // If any order is deleted then call Orders API to get Latest API (Temporary Should be fixed by collabrating with Backend)
//     if (delivery_orders_details?.order_summary && prev_delivery_orders_details?.order_summary && delivery_orders_details?.order_summary && prev_delivery_orders_details?.order_summary.cancelled_total_quantity !== delivery_orders_details?.order_summary.cancelled_total_quantity) {
//       this.setState({ orders_details: {} as OrderDetails });
//       this.getOrderDetails();
//     }

//     if ((prev_selectedMeeting !== selectedMeeting) && !isOpenSidebar && !isSelectedMeetingChanged) {
//       this.setState({ isSelectedMeetingChanged: true });
//     }

//     if (isOpenSidebar && prev_isOpenSidebar !== isOpenSidebar && isSelectedMeetingChanged) {
//       this.getOrderDetails();
//       this.setState({ isSelectedMeetingChanged: false, orders_details: {} as OrderDetails });
//     }

//     if (prev_meeting_slug !== slug) {
//       this.emptyDeliveryLocalOrdersData();
//       this.setState({ orders_details: {} as OrderDetails })
//       this.getOrderDetails();
//     }
//   }

//   componentWillUnmount() {
//     this.emptyDeliveryLocalOrdersData()
//   }

//   emptyDeliveryLocalOrdersData = () => {
//     store.dispatch({
//       type: EMPTY_DELIVERY_ORDERS_DETAILS
//     })
//   }

//   getOrderDetails = (cancelledOrderId = '') => {

//     const {
//       selectedMeeting: { slug },
//       invitedMeetingToken,
//       getOrderDetails,
//       getSelfSignUpOrderDetails
//     } = this.props;

//     const { loggedInUserCompany } = getloggedInUser();

//     const queryParams = `?sort_order=${this.state.sortOrder}&sort_type=${this.state.sortType}`
//     if (loggedInUserCompany.self_signup === true) {
//       getSelfSignUpOrderDetails(slug)
//     } else {
//       getOrderDetails(slug, invitedMeetingToken, queryParams, cancelledOrderId);
//     }
//   }

//   UNSAFE_componentWillReceiveProps(nextProps: OrderDetailsProps) {
//     const { loggedInUser: user } = getloggedInUser();
//     const { order: { delivery_orders_details: next_delivery_orders_details }, closeSidebar, isOpenSidebar } = nextProps;
//     const { order: { delivery_orders_details } } = this.props;
//     if (next_delivery_orders_details && next_delivery_orders_details !== delivery_orders_details) {
//       let userActiveOrdersQuantity = 0, unorderedQuantity = 0, guestOrders: DeliveryAllOrders[] = [], total_active_orders = 0;
//       const userOwnOrders = (next_delivery_orders_details.orders || []).filter(order => {
//         if (order?.status === OrderStatus.Active) {
//           total_active_orders++;
//           !isEmpty(order?.guest_id || order?.share_meeting_id) && guestOrders.push(order);
//           userActiveOrdersQuantity = user.email === order?.user.email ? userActiveOrdersQuantity + (order?.quantity ?? 0) : userActiveOrdersQuantity;
//         } else if (order?.status === OrderStatus.Unordered) {
//           unorderedQuantity++
//         }
//         return order?.user.email === user.email;
//       });

//       if (total_active_orders > 0) {
//         this.setState({
//           guestOrders,
//           userOwnOrders,
//           unorderedQuantity,
//           userActiveOrdersQuantity,
//           orders_details: nextProps.order,
//           guestOrdersQuantity: guestOrders.length,
//         });
//       } else if (isOpenSidebar) {
//         closeSidebar();
//       }

//     }
//     if (nextProps.email.sendStatus && nextProps.email.sendStatus !== this.props.email.sendStatus) {
//       this.setState({ detailsEmail: false })
//       let text = nextProps.email.sendStatus.statusText;
//       if (nextProps.email.sendStatus.status === 200) {
//         showToastNotification(`We've sent you an email.', 'Thank You!'`);
//       } else {
//         showToastNotification(text, 'Oops!');
//       }
//     }
//   }

//   cancelOrder = (id: number) => {
//     const { selectedMeeting: { delivery_at }, fromMenuDetailPopup, invitedMeetingToken, cancelOrder, updatePageOnOrderDelete } = this.props;
//     const deliveryDate = fromMenuDetailPopup ? dayjs(delivery_at).format('D-M-YYYY') : undefined;
//     cancelOrder(id, deliveryDate, invitedMeetingToken || undefined, () => { updatePageOnOrderDelete?.() });
//   }

//   sendEmail = () => {
//     this.setState({ detailsEmail: true });
//     this.props.sendOrderDetailsEmail(this.props.selectedMeeting.slug);
//   };

//   ordersFilterHandler = (filterType: OrdersFilters) => {
//     const { sortType, sortOrder, [filterType]: filter } = this.state;
//     const newFilters = { [filterType]: !filter, sortType: 'status', sortOrder: 'DESC' };
//     // @ts-ignore
//     this.setState({ ...newFilters },
//       () => {
//         // this.generateQueryParams();
//         const { sortType: type, sortOrder: order } = this.state;
//         (sortType !== type || sortOrder !== order) && this.getOrderDetails();
//       }
//     );
//   }

//   // generateQueryParams = () => {
//   //   let parsedValue: { sort_order?: string | string[], sort_type?: string | string[] } = {};
//   //   const { sortOrder, sortType } = this.state;
//   //   if (sortOrder) parsedValue.sort_order = sortOrder;
//   //   if (sortType) parsedValue.sort_type = sortType;

//   //   let queryURL = !isEmpty(parsedValue) ? `?${queryString.stringify(parsedValue)}` : '';
//   //   !this.props.menuDetailPageDeliverySlug && this.props.history.push(`/orders/details/${this.props.match.params.runningmenuSlug}${queryURL}`);
//   // }

//   handleSorting = (sortType: string) => {
//     const tableColumnsWidths: number[] = []
//     const tableColumns = document.querySelectorAll('#menu-detail-page-order-summary-table th');
//     tableColumns?.forEach(col => tableColumnsWidths.push(col.clientWidth))
//     const { selectedMeeting: { slug }, invitedMeetingToken, getOrderDetails } = this.props;
//     let updatedSortOrder = this.state.sortOrder === 'ASC' ? 'DESC' : 'ASC';
//     this.setState({ sortType, sortOrder: updatedSortOrder, tableColumnsWidths }, () => {
//       const serachParams = `?sort_order=${updatedSortOrder}&sort_type=${sortType}`
//       getOrderDetails(slug, invitedMeetingToken, serachParams);
//     })
//   }

//   generatePrint = () => {
//     const printElement = document.getElementById('DeliveryOrderDetailsSidebar')
//     const printElementClone = printElement?.cloneNode(true) as HTMLElement;
//     const filters = printElementClone?.querySelector('#delivery-orders-filters');
//     filters?.remove();
//     const table = printElementClone?.querySelector('#menu-detail-page-order-summary-table') as HTMLElement;
//     table.style.height = 'unset';
//     return printElementClone as Element;
//   }

//   getFilteredOrders = () => {
//     const { showGuestOrders, showMyOrders, guestOrders, userOwnOrders, orders_details: { delivery_orders_details, } } = this.state;
//     if (showGuestOrders && showMyOrders) {
//       return [...userOwnOrders, ...guestOrders];
//     } else if (showMyOrders) {
//       return userOwnOrders;
//     } else if (showGuestOrders) {
//       return guestOrders;
//     } else {
//       return delivery_orders_details?.orders || [];
//     };
//   }

//   EditDeliveryInstructions = () => {
//     this.setState({ deliveryEditableLoader: true })
//     const { selectedMeeting: { slug } } = this.props;
//     this.props.updateSelfSignUpDeliveryDetails(slug, this.state.deliveryInstructionsEdited, this.DeliveryDetailsLoaderHandler)
//   }
//   DeliveryDetailsLoaderHandler = () => {
//     this.setState({ deliveryEditableLoader: false, deliveryEditable: false })
//   }
//   deleteSucessHandler = () => {
//     this.props.closeSidebar()
//     this.setState({ deliveryCancelPopUp: false })

//     window.location.replace('/menu')
//     // window.location.reload()
//   }
//   cancelCartDelivery = () => {
//     // this.setState({ deliveryCancelLoader: true })
//     const { selectedMeeting: { slug } } = this.props;
//     this.props.cancelCartOrder?.(slug, this.state.deliveryCancelReason , this.deleteSucessHandler)
//   }
//   deliveryCancelHandler = (e: any) => {
//     this.setState({ deliveryCancelReason: e.target.value })
//   }

//   render() {

//     const {
//       i18n,
//       closeSidebar,
//       updateOrder,
//       fromMenuDetailPopup,
//       user: {
//         isCompanyAdmin
//       },
//       selectedMeeting,
//       order: {
//         cancel_orders_loading,
//         loading: orders_loading,
//       }
//     } = this.props || {};

//     const {
//       sortType,
//       detailsEmail,
//       showDeleted,
//       showMyOrders,
//       showUnordered,
//       showGuestOrders,
//       guestOrdersQuantity,
//       unorderedQuantity,
//       tableColumnsWidths,
//       orderFilterSearchValue,
//       orders_details: {
//         delivery_orders_details = null
//       },
//       userActiveOrdersQuantity,
//       menuPageOrdersTableBodyHeight,
//     } = this.state;

//     const { order_summary, orders, enable_grouping_orders } = delivery_orders_details || {};
//     const { admin_cutoff_at, cutoff_at, meeting_style, user_copay, runningmenu_fields, price_per_head, ordered_at, end_time, delivery_fee, total_quantity, cancelled_total_quantity, restaurant_name, formatted_address, total_price, meeting_name, meeting_type, avg_per_meal } = order_summary || {}
//     // const selfSignUpOrder = order?.self_signup_delivery_orders_details
//     const selfSignUpOrder = this.props.order?.self_signup_delivery_orders_details
//     // const { cc_processing_fee, delivery_at, delivery_cost, delivery_instructions, driver_tip, items_subtotal, menu_type, runningmenu_type, sales_tax, total, user }: any = selfSignUpOrder
//     const isAdminBuffetDelivery = isCompanyAdmin && meeting_style === 'buffet'
//     const sortingLoading = delivery_orders_details !== null && orders_loading;
//     const wholePageLoading = delivery_orders_details === null && orders_loading;
//     const isPastDelivery = order_summary ? dayjs(isCompanyAdmin ? admin_cutoff_at : cutoff_at).isBefore(new Date()) : true;
//     const { loggedInUserCompany } = getloggedInUser()

//     const columns: TableColumn[] = [
//       { label: 'User', columnName: 'user', sortable: loggedInUserCompany.self_signup === true ? false : true, className: 'border-0' },
//       { label: 'Item', columnName: 'item', sortable: loggedInUserCompany.self_signup === true ? false : true, className: 'border-0' },
//       { label: 'Qty', className: 'cursor-default border-0', style: { width: 65 } },
//       { label: 'Dish Size', hidden: isAdminBuffetDelivery ? false : true, className: 'cursor-default border-0' },
//       { label: 'Serves', hidden: isAdminBuffetDelivery ? false : true, className: 'cursor-default border-0', style: { width: 60 } },
//       { label: 'Price', hidden: total_price === 0, className: 'cursor-default border-0' },
//       { label: 'Total', hidden: isAdminBuffetDelivery ? false : true, className: 'cursor-default border-0', style: { width: 90 } },
//       { label: 'Group', hidden: Boolean(!enable_grouping_orders && orders?.filter((order) => order?.status === OrderStatus.Active)[0].group), className: 'cursor-default border-0', style: { width: 70 } },
//       { label: 'Status', columnName: 'status', sortable: loggedInUserCompany.self_signup === true ? false : true, className: 'border-0', style: { width: 90 } },
//       { label: 'Action', hidden: isPastDelivery || (loggedInUserCompany.self_signup === true), className: 'text-center cursor-default border-0', style: { width: 95 } }
//     ];

//     const filters = [
//       ...(isCompanyAdmin ? [
//         { label: 'My Orders', id: 'showMyOrders', disabled: userActiveOrdersQuantity === 0, total: userActiveOrdersQuantity, checked: showMyOrders },
//         { label: 'Not Ordered', id: 'showUnordered', disabled: unorderedQuantity === 0, total: unorderedQuantity, checked: showUnordered },
//         { label: 'Guest Orders', id: 'showGuestOrders', disabled: guestOrdersQuantity === 0, total: guestOrdersQuantity, checked: showGuestOrders },
//       ] : []),
//       { label: 'Cancelled Orders', id: 'showDeleted', disabled: !cancelled_total_quantity || cancelled_total_quantity === 0, total: cancelled_total_quantity, checked: showDeleted },
//     ]
//     // let OrderSummary;
//     let OrderDetails;
//     let ordersList = this.getFilteredOrders();


//     let singleRowStringifiedData = ``;
//     const appendData = (newData: string | number) => {
//       singleRowStringifiedData += newData
//     }

//     // const cart_cutoff = dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date())
//     if (order_summary && (loggedInUserCompany.self_signup === false)) {
//       OrderDetails = 
//       <tbody className='bg-white'>
//         {(ordersList || [])?.map((order, index) => {
//           const isActiveOrder = order?.status === OrderStatus.Active;
//           const isCancelledOrder = order?.status === OrderStatus.Cancelled;
//           const isUnorderedOrder = order?.status === OrderStatus.Unordered;
//           if (order) {
//             singleRowStringifiedData = ``;
//             const showUserAndCompanyPaidPrices = isCompanyAdmin && !isUnorderedOrder && order?.user.user_type !== UserType.ADMIN && user_copay === 1;
//             const priceLabelValue = showUserAndCompanyPaidPrices ?
//               Number((order?.user_price ?? 0) + (order?.company_price ?? 0)).toFixed(2) :
//               (order?.price === null ? 'N/A' : Number(order?.price).toFixed(2));

//             const row = <React.Fragment key={`${order?.id}` + index}>
//               {(isActiveOrder) ||
//                 (showDeleted === true && isCancelledOrder) ||
//                 (showUnordered === true && isUnorderedOrder) ?
//                 <tr>
//                   <>
//                     {/* label: 'User' */}
//                     <td>
//                       <a href={'mailto:' + order?.user.email} className="text-decoration-none text-gray-primary" >
//                         <>
//                           {appendData(order?.user.name)}
//                           <span className='text-capitalize long-table-text'> {order?.user.name}</span>
//                         </>
//                       </a>
//                     </td>
//                     {/* label: 'Item' */}
//                     <td className={`fooditem-text-capitalize long-table-text ${isCancelledOrder ? 'cancelled-affect ' : ''}`}>
//                       <>
//                         {appendData(order?.fooditem?.name ?? 'N/A')}
//                         {order?.fooditem ? order?.fooditem.name : 'N/A'}
//                       </>
//                       <br />
//                       {order?.remarks &&
//                         <p className='m-0'>
//                           <>
//                             {appendData(`Remarks: ${order?.remarks}`)}
//                             <b style={{ fontWeight: 600 }} className='text-capitalize'>Remarks: </b>
//                             <span className='text-muted text-wrap long-table-text'>
//                               {order?.remarks}
//                             </span>
//                           </>
//                         </p>
//                       }
//                       {order?.selected_options &&
//                         <p className='m-0'>
//                           <>
//                             {appendData(`Options: ${order?.selected_options}`)}
//                             <b style={{ fontWeight: 600 }} className='text-capitalize'>Options: </b>
//                             <span className='text-muted text-wrap'>
//                               {order?.selected_options}
//                             </span>
//                           </>
//                         </p>
//                       }
//                     </td>
//                     {/* label: 'Qty' */}
//                     <td style={{ width: 65 }} className={`text-nowrap ${isCancelledOrder ? 'cancelled-affect' : ''}`}>
//                       <>
//                         {appendData(order?.quantity ?? 'N/A')}
//                         {order?.quantity ? order?.quantity : 'N/A'}
//                       </>
//                     </td>
//                     {/* Buffet Dishsizes and Serve Count */}
//                     {isAdminBuffetDelivery ?
//                       <>
//                         {/* label: 'Dish Size' */}
//                         <td className='text-nowrap'>
//                           <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
//                             <>
//                               {appendData(order?.fooditem?.dishsize ?? 'N/A')}
//                               {order?.fooditem ? order?.fooditem.dishsize : 'N/A'}
//                             </>
//                           </span>
//                         </td>
//                         {/* label: 'Serve Count' */}
//                         <td className='text-nowrap' style={{ width: 60 }}>
//                           <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
//                             <>
//                               {appendData(order?.fooditem?.serve_count ?? 'N/A')}
//                               {order?.fooditem ? order?.fooditem.serve_count : 'N/A'}
//                             </>
//                           </span>
//                         </td>
//                       </>
//                       : null
//                     }
//                     {/* label: 'Price' */}
//                     {(isCompanyAdmin) ?
//                       <td className={`${isCancelledOrder ? 'cancelled-affect' : ''} `}>
//                         <>
//                         {appendData(priceLabelValue)}
//                         ${priceLabelValue ? Number(priceLabelValue).toFixed(2) : '00.00'}
//                         </>
//                         {showUserAndCompanyPaidPrices &&
//                           <>
//                             {appendData(`User paid : $${order?.user_price} Company paid : $${order?.company_price}`)}
//                             <p className='text-muted mb-0'>User paid : ${order?.user_price}</p>
//                             <p className='text-muted mb-0'>Company paid : ${order?.company_price}</p>
//                           </>
//                         }
//                         <br />
//                       </td>
//                       :
//                       total_price !== 0 ?
//                         <td>
//                           <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
//                             <>
//                               {appendData(order?.user_price !== null ? '$' + Number(order?.user_price).toFixed(2) : 'N/A')}
//                               {order?.user_price !== null ? '$' + Number(order?.user_price).toFixed(2) : 'N/A'}
//                             </>
//                           </span>
//                         </td> : null
//                     }
//                     {/* label: 'Total' */}
//                     {meeting_style === 'buffet' ?
//                       <td style={{ width: 90 }}>
//                         <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
//                           <>
//                             {appendData(order?.fooditem ? '$' + Number(parseFloat(`${order?.fooditem.total_price}`)).toFixed(2) : 'N/A')}
//                             {order?.fooditem ? '$' + Number(parseFloat(`${order?.fooditem.total_price}`)).toFixed(2) : 'N/A'}
//                           </>
//                         </span>
//                       </td> : null}
//                     {/* label: 'Group' */}
//                     {enable_grouping_orders && order?.group ?
//                       <td className='padding-left-15 group-overflow'>
//                         <>
//                           {appendData(order?.group)}
//                           {order?.group}
//                         </>
//                       </td>
//                     :
//                       <td>
//                         {isUnorderedOrder && orders?.filter((order) => isActiveOrder)[0]?.group ? 'N/A' : ''}
//                       </td>
//                     }
//                     {/* label: 'Status' */}
//                     {appendData(isUnorderedOrder ? 'Not Ordered' : order?.status)}
//                     <td style={{ width: 90 }} className='text-nowrap'>
//                       <span className={`badge badge-pill text-capitalize badge-${isActiveOrder ? 'success' : (isCancelledOrder ? 'danger' : 'warning')}`}>
//                         {isUnorderedOrder ? 'Not Ordered' : order?.status}
//                       </span>
//                     </td>
//                     {/* label: 'Action' */}
//                     {isPastDelivery ? null :
//                       <>
//                         {(isCancelledOrder || isUnorderedOrder || isPastDelivery) ?
//                           <td></td> :
//                           <td style={{ width: 95 }} className='text-nowrap'>
//                             <div className='text-nowrap'>
//                               {fromMenuDetailPopup &&
//                                 <button className='btn btn-light mr-2' onClick={() => updateOrder?.(order?.id!)}>
//                                   <i className='fas fa-edit text-primary' aria-hidden='true'></i>
//                                 </button>}
//                               <button
//                                 className='btn btn-light'
//                                 disabled={cancel_orders_loading[cancel_order_loading_key_prefix + order?.id]}
//                                 onClick={() => this.cancelOrder(order?.id!)}
//                               >
//                                 {cancel_orders_loading[cancel_order_loading_key_prefix + order?.id] ?
//                                   <span className='text-danger cstm-remove-spinner float-right'>
//                                     <Spinner spinnerSizeClass='spinner-border-sm' />
//                                   </span> :
//                                   <i className='far fa-trash-alt text-danger float-right text-right '></i>
//                                 }
//                               </button>
//                             </div>
//                           </td>
//                         }
//                       </>
//                     }
//                   </>
//                 </tr> : null
//               }
//             </React.Fragment>
//             return orderFilterSearchValue ?
//               (singleRowStringifiedData.toLowerCase().includes(orderFilterSearchValue.toLowerCase()) ?
//                 row : null) :
//               row
//           } else { // When Order is not Present (NULL)
//             return null;
//           }
//         })
//         }
//         </tbody>
//     } else if (selfSignUpOrder) {
//       OrderDetails =
//         <tbody className='bg-white'>
//           {(selfSignUpOrder.orders || [])?.map((order, index) => {
//             if (order) {
//               singleRowStringifiedData = ``;
//               const row = <React.Fragment key={`${order?.id}` + index}>
//                 {
//                     <tr>
//                       <>
//                         {/* label: 'User' */}
//                         <td>
//                           <>
//                             {appendData(selfSignUpOrder.user.delivery_name)}
//                             <span className='text-capitalize long-table-text'> {selfSignUpOrder.user.delivery_name}</span>
//                           </>
//                         </td>
//                         {/* label: 'Item' */}
//                         <td className={`fooditem-text-capitalize long-table-text`}>
//                           <>
//                             {appendData(order?.fooditem_name ?? 'N/A')}
//                             {order?.fooditem_name ? order?.fooditem_name : 'N/A'}
//                           </>
//                           <br />

//                           {order?.options &&
//                             <p className='m-0'>
//                               <>
//                                 {appendData(`Options: ${order?.options}`)}
//                                 <b style={{ fontWeight: 600 }} className='text-capitalize'>Options: </b>
//                                 <span className='text-muted text-wrap'>
//                                   {order?.options?.map((option, idx) => {
//                                     return <span key={idx} className='px-1'><br/>{option.description} </span>
//                                   })}
//                                 </span>
//                               </>
//                             </p>
//                           }
//                         </td>
//                         {/* label: 'Qty' */}
//                         <td style={{ width: 65 }} className={`text-nowrap`}>
//                           <>
//                             {appendData(order?.quantity ?? 'N/A')}
//                             {order?.quantity ? order?.quantity : 'N/A'}
//                           </>
//                         </td>
//                         {/* Buffet Dishsizes and Serve Count */}
//                         {/* label: 'Price' */}
//                         <td>
//                           <span>
//                             <>
//                               {appendData(order?.total_price !== null ? '$' + Number(order?.total_price).toFixed(2) : 'N/A')}
//                               {order?.total_price !== null ? '$' + Number(order?.total_price).toFixed(2) : 'N/A'}
//                             </>
//                           </span>
//                         </td>
//                         {/* label: 'Total' */}
//                         {/* label: 'Group' */}
//                         {order?.group ?
//                           <td className='padding-left-15 group-overflow'>
//                             <>
//                               {appendData(order?.group)}
//                               {order?.group}
//                             </>
//                           </td>
//                           :
//                           <td className='padding-left-15 group-overflow'>
//                             N/A
//                           </td>
//                         }
//                         {/* label: 'Status' */}
//                         <td style={{ width: 90 }} className='text-nowrap'>
//                           <span className={`badge badge-pill text-capitalize badge-success`}>
//                             Active
//                           </span>
//                         </td>
//                         {/* label: 'Action' */}
//                       </>
//                     </tr>
//                 }
//               </React.Fragment>
//               return orderFilterSearchValue ?
//                 (singleRowStringifiedData.toLowerCase().includes(orderFilterSearchValue.toLowerCase()) ?
//                   row : null) :
//                 row
//             } else { // When Order is not Present (NULL)
//               return null;
//             }
//           })
//           }
//         </tbody>
//     }


//     return <>
//       {wholePageLoading ? <DeliveryOrderDetailsLoading closeSidebar={closeSidebar} /> :
//         // Delivery Details Header and Filters Section
//         <section id='menu-detail-page-order-summary' style={{ color: 'black' }}>
//           {/* Sidebar Header */}
//           <div className='px-3 px-md-4 bg-white d-flex justify-content-between align-items-center border-bottom' style={{paddingTop:'20px',paddingBottom:'20px'}}>
//             <h4 className='text-capitalize m-0 cursor-default fw-600 fs-sm-16' style={{ color: 'black' }}>
//               {meeting_name || selectedMeeting.runningmenu_name}
//               <span style={{ fontSize: 12 }} className='badge badge-pill badge-secondary ml-2 text-capitalize'>
//                 {meeting_type}
//               </span>
//             </h4>
//             <i onClick={() => closeSidebar?.()} className='cursor-pointer p-1 m-1 fas fa-times'></i>
//           </div>
//           {/* Order Details Section*/}
//           <div className='px-3 px-md-4'>
//             {/* Main Header For Order Details Section*/}
//             {/* Check for Self-SignUp */}
//             {(loggedInUserCompany.self_signup === false) ? <div className='d-flex justify-content-between py-2'>
//               <h5
//                 className='m-0 align-self-start py-1'
//                 style={{ fontSize: 22 }}>
//                 Order Details 
//               </h5>
//               <div className='d-flex align-items-center align-self-end py-1'>
//                 <button
//                   type='button'
//                   disabled={detailsEmail}
//                   onClick={this.sendEmail}
//                   style={{ minWidth: 88, height: 34, border: '1px solid #157FFC' }}
//                   className='btn btn-outline-primary py-1 mx-1 d-flex align-items-center'
//                 >
//                   <i className='fas fa-envelope mr-2'></i>
//                   <span className='flex-grow-1 text-center'>
//                     {detailsEmail === true ?
//                       <Spinner spinnerSizeClass='spinner-border-sm' /> :
//                       i18n && i18n.t('Email')
//                     }
//                   </span>
//                 </button>
//                 <ReactToPrint
//                   trigger={() =>
//                     <button
//                       type='button'
//                       onClick={() => window.print?.()}
//                       className='btn btn-outline-primary mx-1 py-1'
//                       style={{ minWidth: 88, border: '1px solid #157FFC' }}
//                     >
//                       <i className='fas fa-print mr-2'></i> {i18n && i18n.t('Print')}
//                     </button>}
//                   //@ts-ignore
//                   content={this.generatePrint}
//                 />

//               </div>
//             </div>
//               :
//               <div className='d-flex justify-content-between pt-4 row'>
//                 <div className='d-flex col-md-4 col-lg-4'>
//                   <span className="icons-wrapper">
//                     <img src="/imgs/SVGs/calendar.svg" alt="Delivery-Date" className='svg-dimensions-md'/>
//                   </span>
//                   <div  className='pl-2 pl-md-3 pb-1 text-truncate d-flex align-items-center justify-content-between d-md-block w-100'>
//                     <p 
//                      className='fs-18 fw-600 text-gray-600 m-0 text-truncate view-details-headings'>{selfSignUpOrder?.delivery_at}</p>
//                     {/* <span className='badge-outline-primary cursor-pointer' title='Cutoff Description Tooltip' >
//                       {dayjs(selfSignUpOrder?.cutoff_at).toNow(true)}
//                       </span> */}
//                     {dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) ?
//                         <span data-tip
//                         data-for='delivery-cutoff' className='badge-outline-primary cursor-pointer'>
//                         Cutoff {`in ${dayjs(selfSignUpOrder?.cutoff_at).toNow(true)}`}
//                         </span> :  <span data-tip
//                     data-for='delivery-cutoff' className='badge-outline-danger cursor-pointer'>Cutoff {`was ${dayjs(selfSignUpOrder?.cutoff_at).fromNow()}`}</span>
//                         }
//                     {/* <span className='badge-outline-danger cursor-pointer'>{dayjs(selfSignUpOrder?.cutoff_at).fromNow()}</span> */}
//                     {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
//                         <ReactTooltip place='right' type='dark' id='delivery-cutoff' effect='solid' className='react-tooltip' >
//                           <span>
//                             This is the time until which you may cancel your order?.After this time<br/>
//                              the order is sent to the restaurant for preparation.<br/>
//                             Orders cannot be canceled after this time.
//                           </span>
//                         </ReactTooltip>
//                       }
//                   </div>
//                 </div>
                    
//                 <div className='d-flex col-md-5 col-lg-5 mt-2 mt-md-0'>
//                   <span className="icons-wrapper ml-0 ml-md-4">
//                     <img src="/imgs/SVGs/location-icon.svg" alt="Delivery-Date" className='svg-dimensions-md'/>
//                   </span>
//                   <div className='d-flex align-items-center justify-content-between w-100'>
//                   <div className='pl-2 pl-md-3 pb-1 text-truncate address-details'>
//                     <p className='fs-18 fw-600 text-gray-600 m-0 text-truncate view-details-headings'>{selfSignUpOrder?.delivery_address}</p>
//                     <p className='fs-16 m-0 text-gray-600 fw-normal fs-sm-11'>{selfSignUpOrder?.meeting_name}</p>
//                   </div>
//                   <div className="d-block d-md-none">
//                       {(dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date())) ?
//                       <button
//                         className='btn btn-sm btn-danger py-1 cancel-delivery-btn'
//                         disabled={dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) ? false : true}
//                         onClick={() => this.setState({ deliveryCancelPopUp: true })}
//                       // title='Sorry, unable to cancel delivery after cutoff.'
//                       >
//                         {/* <i className="far fa-calendar-times mx-2"></i> */}
//                         <span className='fw-600' style={{fontSize:'11px'}}>Cancel Delivery</span>
//                       </button>
//                       :
//                       <p
//                         data-tip
//                         data-for='cancel-delivery'
//                         className='btn btn-sm btn-danger py-1 cancel-delivery-btn'
//                         style={{ opacity: '0.5' }}
//                       >
//                         {/* <i className="far fa-calendar-times mx-2"></i> */}
//                         <span className='fw-600' style={{fontSize:'11px'}}>Cancel Delivery</span>
//                       </p>
//                     }
//                     {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
//                       <ReactTooltip place='top' type='dark' id='cancel-delivery' effect='solid' className='react-tooltip'>
//                         <span>Sorry, unable to cancel delivery after cutoff.</span>
//                       </ReactTooltip>
//                     }
//                   </div>
//                   </div>
//                 </div>
//                 <div className='align-items-center justify-content-end py-0 py-md-1 col-md-3 col-lg-3 d-none d-md-block'>
//                   {(dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date())) ?
//                     <button
//                       className='btn btn-sm btn-danger py-2 cancel-delivery-btn d-none d-md-block ml-auto'
//                       disabled={dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) ? false : true}
//                       onClick={() => this.setState({ deliveryCancelPopUp: true })}
//                     // title='Sorry, unable to cancel delivery after cutoff.'
//                     >
//                       <i className="far fa-calendar-times mx-2"></i>
//                       <span className='ps-2 fw-600'>Cancel Delivery</span>
//                     </button>
//                     :
//                     <p
//                       data-tip
//                       data-for='cancel-delivery'
//                       className='btn btn-sm btn-danger py-2 cancel-delivery-btn'
//                       style={{ opacity: '0.5' }}
//                     >
//                       <i className="far fa-calendar-times mx-2"></i>
//                       <span className='ps-2 fw-600'>Cancel Delivery</span>
//                     </p>
//                   }
//                   {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
//                     <ReactTooltip place='top' type='dark' id='cancel-delivery' effect='solid' className='react-tooltip'>
//                       <span>Sorry, unable to cancel delivery after cutoff.</span>
//                     </ReactTooltip>
//                   }
//                 </div>
//               </div>
//             }
//             {/* New Design View details */}
//             {/* Delivery Restaurant Address and Runningmenu Fields */}
//             {/* Check for Self-SignUp */}
//             {(loggedInUserCompany.self_signup === false) ? <div className='row'>
//               <div className='col-12 col-md-3 my-1 d-flex flex-column justify-content-between'>
//                 <p style={{ color: '#646464' }} className='d-flex'>
//                   <i className='fas fa-map-marker-alt mr-2 mt-1' style={{ fontSize: 20 }}></i>
//                   <span>{formatted_address}</span>
//                 </p>
//                 <p style={{ color: '#646464' }} className='d-flex'>
//                   <i className='fas fa-store mr-2 mt-1'></i>
//                   <span>{restaurant_name}</span>
//                 </p>
//               </div>
//               <div className='col-12 col-md-9 my-1 d-flex justify-content-md-end' style={{ overflow: 'auto' }}>
//                 <div>
//                   <div className='d-flex'>
//                     <div className='px-3 text-nowrap'>
//                       <p style={orderDetailHeading}>Delivery Time</p>
//                       {order_summary ?
//                         <p className='mb-1'>{dayjs(ordered_at).format('ddd MMM DD')} at {end_time}</p> : ''
//                       }
//                     </div>
//                     {isCompanyAdmin ?
//                       <>
//                         <div className='px-3 text-nowrap'>
//                           <p style={orderDetailHeading} >Style</p>
//                           <p className='text-capitalize mb-1'>{meeting_style}</p>
//                         </div>
//                         <div className='px-3 text-nowrap'>
//                           <p style={orderDetailHeading} >Orders</p>
//                           <p className='mb-1'>{total_quantity ?? 0}</p>
//                         </div>
//                         <div className='px-3 text-nowrap'>
//                           <p style={orderDetailHeading} >
//                             {meeting_style === 'individual' ? 'Avg. Per Meal' : 'Price Per Head'}
//                           </p>
//                           <p className='mb-1'>
//                             {meeting_style === 'individual' ? `$${avg_per_meal ? Number(avg_per_meal).toFixed(2) : '00.00'}` : `${price_per_head ? Number(price_per_head).toFixed(2) : '00.00'}`}
//                           </p>
//                         </div>
//                         <>
//                           {!isEmpty(delivery_fee) &&
//                             <div className='px-3 text-nowrap'>
//                               <p style={orderDetailHeading} >Delivery Fee</p>
//                               <p className='mb-1'>${delivery_fee ? Number(delivery_fee).toFixed(2) : '00.00'}</p>
//                             </div>
//                           }
//                         </>
//                       </> :
//                       null
//                     }
//                     <div className='px-3 text-nowrap'>
//                       <p style={orderDetailHeading} >Total</p>
//                       <p className='mb-1' style={{ color: '#157FFC', fontSize: 24, fontWeight: 600 }}> ${total_price ? Number(total_price).toFixed(2) : '00.00'}</p>
//                     </div>
//                   </div>
//                   {isCompanyAdmin ?
//                     <div className='d-flex mb-2'>
//                       {runningmenu_fields?.map(field =>
//                         <div key={field.value + field.name} className='px-3 text-nowrapm-1'>
//                           <p style={orderDetailHeading} >{field.name}</p>
//                           <p className='mb-1'>{field.value}</p>
//                         </div>
//                       )}
//                     </div> :
//                     null
//                   }
//                 </div>
//               </div>
//             </div>
//               :
//               <div className='pt-3'>
//                 <div className='mobile-view-details'>
//                   <div className="row w-100 px-0">
//                     <div className="col-12 px-0 d-flex">
//                       <div className="col-2 text-nowrap">
//                         <p className='delivery-details-heading-label'>Person Name</p>
//                         <p className='delivery-details-heading-value'>{selfSignUpOrder?.user.delivery_name}</p>
//                       </div>
//                       <div className="col-2 text-nowrap">
//                         <p className='delivery-details-heading-label'>Phone Number</p>
//                         <p className='delivery-details-heading-value'>{selfSignUpOrder?.user.phone_number}</p>
//                       </div>
//                       <div
//                         className="col-8 text-nowrap text-truncate pb-1"
//                       >
//                         <p className='delivery-details-heading-label d-flex w-100'>
//                           Delivery Instructions
//                           {dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) &&
//                             <span className='d-flex justify-content-between w-100'>
//                               {this.state.deliveryEditable ?
//                                 <span className='fs-16 fw-600 pl-3 cursor-pointer ml-auto' style={{ color: '#167FFB' }} onClick={this.EditDeliveryInstructions}>Done</span>
//                                 :
//                                 <span className='fs-16 fw-600 pl-3 cursor-pointer' style={{ color: '#167FFB' }} onClick={() => this.setState({ deliveryEditable: true })}>Edit</span>
//                               }
//                             </span>
//                           }
//                         </p>
//                         {this.state.deliveryEditableLoader ? <Skeleton height={30} width={580} /> :
//                           (this.state.deliveryEditable ?
//                             <input type="text" className='form-control' style={{ backgroundColor: '#fafcfe' }} value={this.state.deliveryInstructionsEdited} onChange={(e) => { this.setState({ deliveryInstructionsEdited: e.target.value }) }} />
//                             :
//                             <> {(selfSignUpOrder?.delivery_instructions && selfSignUpOrder?.delivery_instructions?.length > 60) ?
//                               <p
//                                 data-tip
//                                 data-for='delivery-instructions'
//                                 className='delivery-details-heading-value text-truncate'>{selfSignUpOrder?.delivery_instructions}
//                               </p>
//                               :
//                               <p
//                                 className='delivery-details-heading-value'
//                               >
//                                 {selfSignUpOrder?.delivery_instructions}
//                               </p>
//                             }</>
//                           )
//                         }
//                         {(selfSignUpOrder?.delivery_instructions && selfSignUpOrder?.delivery_instructions?.length > 60) && window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
//                           <ReactTooltip place='top' type='dark' id='delivery-instructions' effect='solid' className='react-tooltip w-50'>
//                             <p className='text-wrap'>{selfSignUpOrder?.delivery_instructions}</p>
//                           </ReactTooltip>
//                         }
//                       </div>
//                     </div>
//                   </div>
//                   <div className="row w-100 px-0 mt-32 mb-32">
//                     <div className="col-12 px-0 d-flex">
//                       <div className="col-2 text-nowrap">
//                         <p className='delivery-details-heading-label'>Items Subtotal</p>
//                         <p className='delivery-details-heading-value'>${selfSignUpOrder?.items_subtotal ? Number(selfSignUpOrder?.items_subtotal).toFixed(2) : '00.00'}</p>
//                       </div>
//                       <div className="col-2 text-nowrap">
//                         <p className='delivery-details-heading-label'> Sales Tax</p>
//                         <p className='delivery-details-heading-value'>${selfSignUpOrder?.sales_tax ? Number(selfSignUpOrder.sales_tax).toFixed(2) : '00.00'}</p>
//                       </div>
//                       <div className="col-2 text-nowrap">
//                         <p className='delivery-details-heading-label'>Delivery Fee</p>
//                         <p className='delivery-details-heading-value'>${selfSignUpOrder?.delivery_cost ? Number(selfSignUpOrder.delivery_cost).toFixed(2) : '00.00'}</p>
//                       </div>
//                       <div className="col-2 text-nowrap">
//                         <p className='delivery-details-heading-label'>Driver Tip</p>
//                         <p className='delivery-details-heading-value'>${selfSignUpOrder?.driver_tip ? Number(selfSignUpOrder.driver_tip).toFixed(2) : '00.00'}</p>
//                       </div>
//                       <div className="col-3 text-nowrap">
//                         <p className='delivery-details-heading-label'>Credit Card Processing Fee</p>
//                         <p className='delivery-details-heading-value'>${selfSignUpOrder?.cc_processing_fee ? Number(selfSignUpOrder.cc_processing_fee).toFixed(2) : '00.00'}</p>
//                       </div>
//                       <div className="col-1 text-nowrap">
//                         <p className='delivery-details-heading-label'>Total</p>
//                         <h4 className='fw-600 text-grey-600 m-0 mt-1'>${selfSignUpOrder?.total ? Number(selfSignUpOrder.total).toFixed(2) : '00.00'}</h4>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className='mobile-filter-Carousel'>
//                   <Carousel
//                     swipeable={true}
//                     draggable={true}
//                     showDots={true}
//                     responsive={carousel_responsive}
//                     infinite={false}
//                     autoPlay={false}
//                     autoPlaySpeed={1000}
//                     keyBoardControl={true}
//                     containerClass='carousel-container'
//                     removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
//                     dotListClass='marketplace-custom-dot-list-style'
//                   >
//                     <div className="row w-100 px-0">
//                       <div className="col-12 px-0 d-flex">
//                         <div className="col-6 text-nowrap">
//                           <p className='delivery-details-heading-label'>Person Name</p>
//                           <p className='delivery-details-heading-value'>{selfSignUpOrder?.user.delivery_name}</p>
//                         </div>
//                         <div className="col-6 text-nowrap">
//                           <p className='delivery-details-heading-label'>Phone Number</p>
//                           <p className='delivery-details-heading-value'>{selfSignUpOrder?.user.phone_number}</p>
//                         </div>
//                       </div>
//                       <div className="col-12 text-nowrap text-truncate pt-2" >
//                         <p className='delivery-details-heading-label d-flex w-100'>
//                         Delivery Instructions
//                         {dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) &&
//                           <span className='d-flex justify-content-between w-100'>
//                             {this.state.deliveryEditable ?
//                               <span className='fs-16 fw-600 pl-3 cursor-pointer ml-auto' style={{ color: '#167FFB' }} onClick={this.EditDeliveryInstructions}>Done</span>
//                               :
//                               <span className='fs-16 fw-600 pl-3 cursor-pointer' style={{ color: '#167FFB' }} onClick={() => this.setState({ deliveryEditable: true })}>Edit</span>
//                             }
//                           </span>
//                         }
//                         {/* <span className='d-flex justify-content-between w-100' >
//                           <span className='fs-16 fw-600 pl-3 cursor-pointer' style={{ color: '#167FFB' }} onClick={() => this.setState({ deliveryEditable: true })}>Edit</span>
//                           <span className='fs-16 fw-600 pl-3 cursor-pointer' style={{ color: '#167FFB' }} onClick={this.EditDeliveryInstructions}>Done</span>
//                         </span> */}
//                         </p>
//                         {this.state.deliveryEditableLoader ? <Skeleton height={30} width={580} /> :
//                         (this.state.deliveryEditable ?
//                           <input type="text" className='form-control' style={{ backgroundColor: '#fafcfe' }} value={this.state.deliveryInstructionsEdited} onChange={(e) => { this.setState({ deliveryInstructionsEdited: e.target.value }) }} />
//                           :
//                           <> {(selfSignUpOrder?.delivery_instructions && selfSignUpOrder?.delivery_instructions?.length > 60) ?
//                             <p
//                               data-tip
//                               data-for='delivery-instructions'
//                               className='delivery-details-heading-value text-truncate'>{selfSignUpOrder?.delivery_instructions}
//                             </p>
//                             :
//                             <p
//                               className='delivery-details-heading-value'
//                             >
//                               {selfSignUpOrder?.delivery_instructions}
//                             </p>
//                           }</>
//                         )
//                         }
//                         {(selfSignUpOrder?.delivery_instructions && selfSignUpOrder?.delivery_instructions?.length > 60) && window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
//                           <ReactTooltip place='top' type='dark' id='delivery-instructions' effect='solid' className='react-tooltip w-50'>
//                             <p className='text-wrap'>{selfSignUpOrder?.delivery_instructions}</p>
//                           </ReactTooltip>
//                         }
//                     </div>
//                 </div>
//                 <div className="row px-0">
//                   <div className="col-12 px-0 d-flex">
//                     <div className="col-3 text-nowrap">
//                       <p className='delivery-details-heading-label'>Items Subtotal</p>
//                       <p className='delivery-details-heading-value'>${selfSignUpOrder?.items_subtotal ? Number(selfSignUpOrder?.items_subtotal).toFixed(2) : '00.00'}</p>
//                     </div>
//                     <div className="col-3 text-nowrap">
//                       <p className='delivery-details-heading-label'> Sales Tax</p>
//                       <p className='delivery-details-heading-value'>${selfSignUpOrder?.sales_tax ? Number(selfSignUpOrder.sales_tax).toFixed(2) : '00.00'}</p>
//                     </div>
//                     <div className="col-3 text-nowrap">
//                       <p className='delivery-details-heading-label'>Delivery Fee</p>
//                       <p className='delivery-details-heading-value'>${selfSignUpOrder?.delivery_cost ? Number(selfSignUpOrder.delivery_cost).toFixed(2) : '00.00'}</p>
//                     </div>
//                     <div className="col-3 text-nowrap">
//                       <p className='delivery-details-heading-label'>Driver Tip</p>
//                       <p className='delivery-details-heading-value'>${selfSignUpOrder?.driver_tip ? Number(selfSignUpOrder.driver_tip).toFixed(2) : '00.00'}</p>
//                     </div>
//                   </div>
//                   <div className="col-12 px-0 d-flex pt-2">
//                     <div className="col-6 text-nowrap">
//                       <p className='delivery-details-heading-label'>Credit Card Processing Fee</p>
//                       <p className='delivery-details-heading-value'>${selfSignUpOrder?.cc_processing_fee ? Number(selfSignUpOrder.cc_processing_fee).toFixed(2) : '00.00'}</p>
//                     </div>
//                     <div className="col-6 text-nowrap">
//                       <p className='delivery-details-heading-label'>Total</p>
//                       <h4 className='fw-600 text-grey-600 m-0 mt-1 fs-sm-14'>${selfSignUpOrder?.total ? Number(selfSignUpOrder.total).toFixed(2) : '00.00'}</h4>
//                     </div>
//                   </div>
//                 </div>
//                 </Carousel>
//                 </div>
//               </div>
//             }
//             </div>
//             <hr className='mt-1' />
//             {/* Delivery Orders Filters*/}
//             <div className="px-3 px-md-4">
//             <div id='delivery-orders-filters' className='d-flex align-items-center justify-content-between pt-2 pb-3'>
//               {(loggedInUserCompany.self_signup === true) && <h3 className='m-0 fw-600 fs-sm-16' style={{ fontSize: 22 }}>Items List</h3>}


//                 {/* Orders Status Filters */}
//                 {/* Filter starts here comment */}
//                 { (loggedInUserCompany.self_signup === false) && <div className='d-flex align-items-center my-1 align-self-start flex-wrap'>
//                   <div className='align-self-start align-self-md-center' style={{'position':'relative'}}>
//                     <button
//                       style={{ backgroundColor: '#157FFC' }}
//                       type='button'
//                       // data-toggle='dropdown'
//                       // collapse
//                       id='ordersFiltersDropdown'
//                       className='btn btn-primary border-0 text-nowrap'
//                       onClick={()=>this.setState({toggleDropdown:!this.state.toggleDropdown})}
//                     >
//                       <i className='fas fa-filter mr-2' aria-hidden='true'></i>
//                       Filters
//                       <i className={`ml-3 fas fa-chevron-down`} aria-hidden='true'></i>
//                     </button>
//                     {this.state.toggleDropdown && <div className='item-list-dropDown m-0 p-2' aria-labelledby='ordersFiltersDropdown'>
//                       {filters.map((filter, index) =>
//                         <div
//                           key={filter.id}
//                           className={`d-flex align-items-center ${filter.disabled ? 'disabled-opacity' : ''} ${index === filters.length - 1 ? '' : 'pb-2'}`}
//                         >
//                           <input
//                             type='checkbox'
//                             className='cursor-pointer'
//                             id={filter.id}
//                             disabled={filter.disabled}
//                             onChange={() => this.ordersFilterHandler(filter.id as OrdersFilters)}
//                             checked={filter.checked}
//                           />
//                           <label htmlFor={filter.id} className='m-0 ml-2 cursor-pointer' >
//                             {filter.label}
//                           </label>
//                         </div>)
//                       }
//                     </div>}
//                   </div>
//                   <div className='px-md-3 d-none d-md-flex flex-wrap align-self-start' style={{ transition: 'all .2s' }}>
//                     {filters.filter(filter => filter.checked).map(appliedFilter =>
//                       <span
//                         key={appliedFilter.id + appliedFilter.label}
//                         className='py-1 my-1 mx-2 px-2 cursor-default text-nowrap'
//                         style={{ borderRadius: 20, border: '1px solid #157FFC', color: '#157FFC' }}
//                       >
//                         {appliedFilter.label} ({appliedFilter.total})
//                         <i
//                           className={`cursor-pointer ml-3 far fa-times-circle`}
//                           onClick={() => this.setState(prevState => ({ ...prevState, [appliedFilter.id]: false }))}
//                         ></i>
//                       </span>
//                     )}
//                   </div>
//                 </div>
//                 }

//                 {/* <div className='d-flex align-items-center my-1 align-self-start flex-wrap'>
//                   <div className='align-self-start align-self-md-center'>
//                     <button
//                       style={{ backgroundColor: '#157FFC' }}
//                       type='button'
//                       data-toggle='dropdown'
//                       id='ordersFiltersDropdown'
//                       className='btn btn-primary border-0 text-nowrap'
//                     >
//                       <i className='fas fa-filter mr-2' aria-hidden='true'></i>
//                       Filters
//                       <i className={`ml-3 fas fa-chevron-down`} aria-hidden='true'></i>
//                     </button>
//                     <div className='dropdown-menu m-0 p-2' aria-labelledby='ordersFiltersDropdown'>
//                       {filters.map((filter, index) =>
//                         <div
//                           key={filter.id}
//                           className={`d-flex align-items-center ${filter.disabled ? 'disabled-opacity' : ''} ${index === filters.length - 1 ? '' : 'pb-2'}`}
//                         >
//                           <input
//                             type='checkbox'
//                             className='cursor-pointer'
//                             id={filter.id}
//                             disabled={filter.disabled}
//                             onChange={() => this.ordersFilterHandler(filter.id as OrdersFilters)}
//                             checked={filter.checked}
//                           />
//                           <label htmlFor={filter.id} className='m-0 ml-2 cursor-pointer' >
//                             {filter.label}
//                           </label>
//                         </div>)
//                       }
//                     </div>
//                   </div>
//                   <div className='px-md-3 d-none d-md-flex flex-wrap align-self-start' style={{ transition: 'all .2s' }}>
//                     {filters.filter(filter => filter.checked).map(appliedFilter =>
//                       <span
//                         key={appliedFilter.id + appliedFilter.label}
//                         className='py-1 my-1 mx-2 px-2 cursor-default text-nowrap'
//                         style={{ borderRadius: 20, border: '1px solid #157ffc', color: '#157ffc' }}
//                       >
//                         {appliedFilter.label} ({appliedFilter.total})
//                         <i
//                           className={`cursor-pointer ml-3 far fa-times-circle`}
//                           onClick={() => this.setState(prevState => ({ ...prevState, [appliedFilter.id]: false }))}
//                         ></i>
//                       </span>
//                     )}
//                   </div>
//                 </div> */}
//                 {/* Search Filter */}
//                 <div className='d-flex bg-white px-1 pl-3 align-items-center align-self-start align-self-md-center overflow-hidden'
//                   style={{ border: '1px solid #dfdfe3', borderRadius: 20 }}
//                 >
//                   <span>
//                     <i className='fas fa-search' style={{ color: '#999999' }}></i>
//                   </span>
//                   <input
//                     type='text'
//                     placeholder='Search'
//                     style={{ color: '#999999' }}
//                     onChange={({ target: { value } }) => this.setState({ orderFilterSearchValue: value })}
//                     className='flex-grow-1 shadow-none form-control border-0 p-2'
//                   />
//                 </div>
//             </div>
//           </div>
//         </section>
//       }
//       {/* Orders Table */}
//       <section
//         className='menu-detail-page-order-summary-table'
//         id='menu-detail-page-order-summary-table'
//         style={{ height: menuPageOrdersTableBodyHeight, overflow: 'auto' }}
//       >
//         <table className={`table table-hover border-0 ${(wholePageLoading || sortingLoading) ? 'disabled-opacity' : ''}`}>
//           <TableHeader
//             theadStyles={{ backgroundColor: '#fafafa', position: 'sticky', top: 0, border: 0 }}
//             columns={columns}
//             sortingColumn={sortType}
//             handleSorting={this.handleSorting}
//             inlineHeader
//           />
//           {(wholePageLoading || sortingLoading) ?
//             // Loading In case of Sorting or When Order Data is Not Recieved
//             <tbody>
//               {ArrayOfLength(Math.ceil((+menuPageOrdersTableBodyHeight) / 65)).map(index =>
//                 <tr key={index}>
//                   {columns.filter((col) => !col.hidden).map((col, index) =>
//                     <td key={col.label} style={{ minWidth: tableColumnsWidths[index] ?? 'unset', height: 63 }}>
//                       <Skeleton width='100%' height={25} />
//                     </td>
//                   )}
//                 </tr>)
//               }
//             </tbody>
//             : OrderDetails
//           }
//         </table>
//       </section>
//       <Modal
//         isOpen={this.state.deliveryCancelPopUp}
//         onRequestClose={() => this.setState({ deliveryCancelPopUp: false }) }
//         style={customStyles}
//         contentLabel='Example Modal'>
//         <div className='order-modal' id='orderModelBox'>
//           <div className='modal-dialog' style={{ maxWidth: '100%' }}>
//             <div className='modal-content' style={{ border: 'none' }}>
//               <div className={`modal-header bg-white`} style={{ width: '690px' }}>
//                 <h5 className='modal-title' id='modal-title'>  {i18n && i18n.t('Cancel Delivery')} </h5>
//                 <button type='button' onClick={() => this.setState({ deliveryCancelPopUp: false })} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
//               </div>
//               <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
//                 <div id='main' className='container'>
//                 <div className='form-row'>
//                     <div className='col-11 m-auto text-center'>
//                         <span>
//                           <h2 className='text-center'> {i18n && i18n.t('Cancel Delivery.')}</h2>
//                           <p className='text-muted'><span className='text-danger'>{selfSignUpOrder?.orders.length}  {i18n && i18n.t('Orders')}</span>  {i18n && i18n.t('Rewill be cancelled for this meeting.quired')}</p>
//                         </span>
                       
//                     </div>
//                   </div>
//                     <form noValidate className='text-left'>
//                       <div className='form-row'>
//                         <div className='col-12 company-required-field p-3'>
//                           <h6 className='orderform-section-name float-left '>  {i18n && i18n.t('Reason for Cancellation')}</h6>
//                           <h6 className='orderform-section-required muted float-right '> {i18n && i18n.t('Required')}</h6>
//                           <textarea className='form-control'
//                             id='name'
//                             name='cancelReason'
//                             placeholder={i18n ? i18n.t('Prefer different vendor, meeting rescheduled, etc') : ''}
//                             onChange={this.deliveryCancelHandler}>
//                           </textarea>
//                         </div>
//                       </div>
//                     </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//           <div className={`modal-footer bg-white`} style={{ width: '100%' }}>
//             <button
//               type='submit'
//               className='confirm-cancel-delivery ml-3 btn btn-primary delivery-btn-width'
//               onClick={() => this.cancelCartDelivery()}
//               disabled={this.state.deliveryCancelReason === ''}
//             >
//               {i18n && i18n.t('Cancel Delivery')}
//             </button>
//           </div>
//       </Modal>
//     </>
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   order: state.order,
//   email: state.email,
//   i18n: state.i18n.i18n
// });

// export default connect(mapStateToProps, { getOrderDetails, getSelfSignUpOrderDetails, cancelOrder, cancelCartOrder, updateSelfSignUpDeliveryDetails, sendOrderDetailsEmail })(withRouter(DeliveryOrderDetails));


import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import ReactToPrint from 'react-to-print';
import Skeleton from 'react-loading-skeleton';
import Modal from 'react-modal';
// React JSX Components
import Spinner from '../../Common/Spinners/Spinner';
import TableHeader, { TableColumn } from '../../Common/TableHeader';
import { DeliveryOrdersSidebarMeeting } from '../../Common/Popups/DeliveryOrdersSidebar';
import DeliveryOrderDetailsLoading from '../../Common/Loadings/OrderHistoryPage/DeliveryOrderDetailsLoading';
// Redux Dispatch Actions
import { sendOrderDetailsEmail } from '../../Actions/emailActions';
import { getOrderDetails, cancelOrder, getSelfSignUpOrderDetails, updateSelfSignUpDeliveryDetails } from '../../Actions/orderActions';
// Utilities
import isEmpty from '../../Utilities/is_empty';
// import isEmpty from '../../reducers/is_empty';
import { ArrayOfLength } from '../../Utilities/ArraysUtilities';
import { EMPTY_DELIVERY_ORDERS_DETAILS, MARKETPLACE_PAGE } from '../../Actions/types';
import { UserType } from '../../Interfaces/PayloadsAndResponses/Auth';
import { showToastNotification } from '../../Utilities/showToastNotification';
import { GetloggedInUser, getloggedInUser } from '../../Utilities/getloggedInUser';
import { orderDetailsTranslations } from '../../Translations/orderDetailsTranslations'
// Types
import { cancel_order_loading_key_prefix } from '../../Reducers/orderReducer';
import { DeliveryAllOrders, OrderStatus } from '../../Interfaces/PayloadsAndResponses/Orders';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// CSS
import './sidebar.css'
import ReactTooltip from 'react-tooltip';
import Carousel from 'react-multi-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { cancelCartOrder } from '../../Actions/cartActions';

import { getZonedTime } from '../../Utilities/functions';
import DeleteOrderConfirmationPopup from './DeleteOrderConformationPopup';

const carousel_responsive = {
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '692px',
    padding: '0',
    borderRadius: '18px'
  }
};

type OrdersFilters = 'showUnordered' | 'showMyOrders' | 'showDeleted';
type OrderDetailsProps = {
  user: GetloggedInUser;
  isOpenSidebar: boolean;
  closeSidebar: () => void;
  orderChangeHandler?: () => void;
  invitedMeetingToken?: string;
  fromMenuDetailPopup?: boolean;
  updatePageOnOrderDelete?: () => void;
  updateOrder?: (orderId: number) => void;
  selectedMeeting: DeliveryOrdersSidebarMeeting;
  sendOrderDetailsEmail?: (runningmenuSlug: string) => void;
  updateSelfSignUpDeliveryDetails?: (slug: string, delivery_instructions: string, DeliveryDetailsLoaderHandler: () => void) => void;
  getSelfSignUpOrderDetails?: (slug: string) => void;
  cancelCartOrder?: (slug: string, cancel_reason: string, deleteSucessHandler: () => void) => void;
  getOrderDetails?: (runningmenuSlug: string, shareMeetingToken?: string | undefined, queryParams?: string, canceledOrderId?: string) => void;
  cancelOrder?: (canceledOrderId: number, deliveryDate: string | undefined, shareMeetingToken: string | undefined, canceledOrderCallback: (canceledOrderId?: string) => void) => void;
}

const orderDetailHeading: CSSProperties = {
  color: '#999999',
  fontWeight: 600,
  marginBottom: 5,
}

const DeliveryOrderDetails: FC<OrderDetailsProps> = ({ user, isOpenSidebar, closeSidebar, orderChangeHandler, invitedMeetingToken, fromMenuDetailPopup, updatePageOnOrderDelete, updateOrder, selectedMeeting }) => {
  const [userOwnOrders, setUserOwnOrders] = useState<DeliveryAllOrders[]>([]);
  const [userActiveOrdersQuantity, setUserActiveOrdersQuantity] = useState<number>(0);
  const [guestOrdersQuantity, setGuestOrdersQuantity] = useState<number>(0);
  const [guestOrders, setGuestOrders] = useState<DeliveryAllOrders[]>([]);
  const [unorderedQuantity, setUnorderedQuantity] = useState<number>(0);
  const [detailsEmail, setDetailsEmail] = useState<boolean>(false);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [showGuestOrders, setShowGuestOrders] = useState<boolean>(false);
  const [showUnordered, setShowUnordered] = useState<boolean>(false);
  const [showMyOrders, setShowMyOrders] = useState<boolean>(false);
  const [loadOrderSummary, setLoadOrderSummary] = useState<boolean>(true);
  const [sortType] = useState<string | string[]>('user');
  const [sortOrder] = useState<string | string[]>('ASC');
  const [tableColumnsWidths, setTableColumnsWidths] = useState<number[]>([]);
  const [orderFilterSearchValue, setOrderFilterSearchValue] = useState<string>('');
  const [isSelectedMeetingChanged, setIsSelectedMeetingChanged] = useState<boolean>(false);
  const [menuPageOrdersTableBodyHeight, setMenuPageOrdersTableBodyHeight] = useState<number | string>('unset');
  const [toggleDropdown, setToggleDropdown] = useState<boolean>(false);
  const [deliveryInstructionsEdited, setDeliveryInstructionsEdited] = useState<string>('');
  const [deliveryEditable, setDeliveryEditable] = useState<boolean>(false);
  const [deliveryEditableLoader, setDeliveryEditableLoader] = useState<boolean>(false);
  const [deliveryCancelPopUp, setDeliveryCancelPopUp] = useState<boolean>(false);
  const [deliveryCancelReason, setDeliveryCancelReason] = useState<string>('');
  const [orderDeletePopup, setOrderDeletePopup] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<any>(null);

  const dispatch = useDispatch<any>();
  const i18n = useSelector((state: ReduxRootState) => state.i18n?.i18n);
  const email = useSelector((state: ReduxRootState) => state.email);
  const order = useSelector((state: ReduxRootState) => state.order);
  const credit_card= useSelector((state: ReduxRootState) => state?.profile?.profile?.card_details)

  const { order_summary, orders, enable_grouping_orders } = order?.delivery_orders_details || {};
  const { admin_cutoff_at, sd_activation_at, sd_admin_cutoff_at, cutoff_at, sd_cutoff_at, meeting_style, user_copay, runningmenu_fields, price_per_head, ordered_at, time_zone, delivery_fee, invoice_discount, total_quantity, cancelled_total_quantity, restaurant_name, formatted_address, total_price, meeting_name, meeting_type, avg_per_meal } = order_summary || {}
  const selfSignUpOrder = order?.self_signup_delivery_orders_details
  const isAdminBuffetDelivery = user?.isCompanyAdmin && meeting_style === 'buffet'
  const sortingLoading = order?.delivery_orders_details !== null && order?.loading;
  const wholePageLoading = order?.delivery_orders_details === null && order?.loading;

  const isSdMeetingCurrentlyOn = (order_summary?.sd_activation_at) && (dayjs(sd_activation_at).isBefore(new Date()))
  const isSdPastDelivery = (order_summary?.sd_admin_cutoff_at || order_summary?.sd_cutoff_at) ? (dayjs(user?.isCompanyAdmin ? sd_admin_cutoff_at : sd_cutoff_at).isBefore(new Date())) : true
  const isPastDelivery = order_summary ? dayjs(user?.isCompanyAdmin ? admin_cutoff_at : cutoff_at).isBefore(new Date()) : true;
  const { loggedInUserCompany } = getloggedInUser()

  let bothSchedulersActive = false
  if (isSdMeetingCurrentlyOn && dayjs(user?.isCompanyAdmin ? admin_cutoff_at : cutoff_at).isAfter(new Date())) {
    bothSchedulersActive = true
  }
  const orderRef = useRef<any>(order);
  const emailRef = useRef<any>(email)

  const getTableBodyHeight = () => {
    return document.body.clientHeight - ((document.getElementById('menu-detail-page-order-summary')?.clientHeight ?? 0) + 10); // 10 is added to show bottom scrollbar
  }

const handleCloseModal = () => {
  setOrderDeletePopup(false)
}

  useEffect(() => {
    orderRef.current = order;
    emailRef.current = email;
    orderDetailsTranslations();
    document.title = 'Order Details | Chowmill';
    const tableHeight = getTableBodyHeight();
    setMenuPageOrdersTableBodyHeight(tableHeight);
    const { loggedInUserCompany } = getloggedInUser();
    let queryParams = '?sort_order=ASC&sort_type=user';

    if (loggedInUserCompany?.self_signup === true) {
      dispatch(getSelfSignUpOrderDetails(selectedMeeting?.slug));
    } else {
      dispatch(getOrderDetails(selectedMeeting?.slug, invitedMeetingToken, queryParams));
    }
    setTimeout(() => {
      setLoadOrderSummary(false);
    }, 2000);
    // eslint-disable-next-line
  }, [isOpenSidebar, loadOrderSummary]);

  useEffect(() => {
    dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    });
  }, [dispatch])

  useEffect(() => {
    orderRef.current = order
    if (typeof orderRef?.current?.prevDeliveryOrdersDetails !== 'undefined') {
      const { selectedMeeting: prevSelectedMeeting, order: { delivery_orders_details: prevDeliveryOrdersDetails }, isOpenSidebar: prevIsOpenSidebar } = orderRef?.current;
      const { delivery_orders_details } = order;
      const { slug: prevMeetingSlug } = prevSelectedMeeting;
      if ((prevDeliveryOrdersDetails?.orders !== delivery_orders_details?.orders) || (orderRef?.current?.showDeleted !== showDeleted) || (orderRef?.current?.showMyOrders !== showMyOrders) || (orderRef?.current?.showUnordered !== showUnordered)) {
        setMenuPageOrdersTableBodyHeight(getTableBodyHeight());
      }
      if (delivery_orders_details?.order_summary && prevDeliveryOrdersDetails?.order_summary && delivery_orders_details?.order_summary && prevDeliveryOrdersDetails?.order_summary?.cancelled_total_quantity !== delivery_orders_details?.order_summary?.cancelled_total_quantity) {
        //setOrders_details({} as OrderDetails);
        getOrder_Details();
      }
      if ((prevSelectedMeeting !== selectedMeeting) && !isOpenSidebar && !isSelectedMeetingChanged) {
        setIsSelectedMeetingChanged(true);
      }
      if (isOpenSidebar && prevIsOpenSidebar !== isOpenSidebar && isSelectedMeetingChanged) {
        getOrder_Details();
        setIsSelectedMeetingChanged(false);
        //setOrders_details({} as OrderDetails);
      }
      if (prevMeetingSlug !== selectedMeeting.slug) {
        emptyDeliveryLocalOrdersData();
        //setOrders_details({} as OrderDetails);
        getOrder_Details();
      }
    }
    // eslint-disable-next-line
  }, [order, isOpenSidebar, isSelectedMeetingChanged, showDeleted, showMyOrders, showUnordered]);

  const orderDetails = useSelector((state: any) => state.orderDetails);

  useEffect(() => {
    return () => {
      emptyDeliveryLocalOrdersData();
    };
    // eslint-disable-next-line
  }, []);

  const emptyDeliveryLocalOrdersData = () => {
    dispatch({
      type: EMPTY_DELIVERY_ORDERS_DETAILS,
    });
  };

  const getOrder_Details = (cancelledOrderId = '') => {
    const { invitedMeetingToken } = orderDetails;
    const queryParams = `?sort_order=${orderDetails?.sortOrder}&sort_type=${orderDetails?.sortType}`;
    const { loggedInUserCompany } = getloggedInUser();
    if (loggedInUserCompany?.self_signup === true) {
      dispatch(getSelfSignUpOrderDetails(selectedMeeting?.slug));
    } else {
      dispatch(getOrderDetails(selectedMeeting?.slug, invitedMeetingToken, queryParams));
    }
  };

  const updateOrderData = () => {
    orderChangeHandler?.()
    updatePageOnOrderDelete?.()
    getOrder_Details()
  }

  useEffect(() => {
    if (orderRef?.current && orderRef?.current?.delivery_orders_details) {
      const { loggedInUser: user } = getloggedInUser();
      const { delivery_orders_details: next_delivery_orders_details, closeSidebar, isOpenSidebar } = orderRef?.current;
      let userActiveOrdersQuantity = 0, unorderedQuantity = 0, guestOrders: DeliveryAllOrders[] = [], total_active_orders = 0;
      const userOwnOrders = (next_delivery_orders_details.orders || [])?.filter((order: any) => {
        if (order?.status === OrderStatus.Active) {
          total_active_orders++;
          !isEmpty(order?.guest_id || order?.share_meeting_id) && guestOrders.push(order);
          userActiveOrdersQuantity = user?.email === order?.user?.email ? userActiveOrdersQuantity + (order?.quantity ?? 0) : userActiveOrdersQuantity;
        } else if (order?.status === OrderStatus.Unordered) {
          unorderedQuantity++
        }
        return order?.user?.email === user?.email;
      });
      if (total_active_orders > 0) {
        setGuestOrders(guestOrders)
        setUserOwnOrders(userOwnOrders)
        setUnorderedQuantity(unorderedQuantity)
        setUserActiveOrdersQuantity(userActiveOrdersQuantity)
        setGuestOrdersQuantity(guestOrders?.length)
      } else if (isOpenSidebar) {
        closeSidebar();
      }
      if (email?.sendStatus && email?.sendStatus !== emailRef?.current?.sendStatus) {
        setDetailsEmail(false)
        let text = emailRef?.current?.email?.sendStatus?.statusText;
        if (email?.sendStatus.status === 200) {
          showToastNotification(`We've sent you an email.', 'Thank You!'`);
        } else {
          showToastNotification(text, 'Oops!');
        }
      }
    }
  }, [order, isOpenSidebar, isSelectedMeetingChanged, showDeleted, showMyOrders, showUnordered, email.sendStatus, toggleDropdown]);

  const cancel_Order = (id: any) => {
    const { isCompanyAdmin } = getloggedInUser();
    const deliveryDate = fromMenuDetailPopup ? dayjs(selectedMeeting?.delivery_at).format('D-M-YYYY') : undefined;
    // Convert total_price to a number
    const totalPriceAsNumber = typeof total_price === 'string' ? parseFloat(total_price) : total_price;
    if (isCompanyAdmin) {
      dispatch(cancelOrder(id, deliveryDate, invitedMeetingToken, () => { updateOrderData() }));
    } else {
      if (totalPriceAsNumber === 0 && credit_card === null) {
        // If total_price is 0 and the card is empty, dispatch the API
        dispatch(cancelOrder(id, deliveryDate, invitedMeetingToken, () => { updateOrderData() }));
      } else if (totalPriceAsNumber && totalPriceAsNumber > 0 && credit_card === null) {
        // If total_price is greater than 0 and the card is empty, open a popup
        setOrderId(id);
        setOrderDeletePopup(true);
      } else if (totalPriceAsNumber && totalPriceAsNumber > 0 && credit_card !== null) {
        // If total_price is greater than 0 and the card is not empty, dispatch the API
        dispatch(cancelOrder(id, deliveryDate, invitedMeetingToken, () => { updateOrderData() }));
      } else {
        dispatch(cancelOrder(id, deliveryDate, invitedMeetingToken, () => { updateOrderData() }));
      }
    }
  };

  const sendEmail = () => {
    setDetailsEmail(true);
    dispatch(sendOrderDetailsEmail(selectedMeeting?.slug));
  };

  const ordersFilterHandler = (filterType: OrdersFilters) => {
    if (filterType === "showMyOrders") {
      setShowMyOrders(!showMyOrders)
    }
    else if (filterType === "showUnordered") {
      setShowUnordered(!showUnordered)
    }
    else if (filterType === "showDeleted") {
      setShowDeleted(!showDeleted)
    }
    else if (filterType === "showGuestOrders") {
      setShowGuestOrders(!showGuestOrders)
    }
    // const newFilters = { [filterType]: true, sortType: 'status', sortOrder: 'DESC' };
    // @ts-ignore
    // setNewFilter(newFilters);
    //   const type = sortType;
    //  const order = sortOrder;
    const { sortType: type, sortOrder: order } = { sortType, sortOrder };

    (sortType !== type || sortOrder !== order) && (getOrder_Details());
  };

  const handleSorting = (sortType: string) => {
    setTableColumnsWidths([])
    const tableColumns = document.querySelectorAll('#menu-detail-page-order-summary-table th');
    tableColumns?.forEach(col => tableColumnsWidths.push(col.clientWidth))
    let updatedSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    const serachParams = `?sort_order=${updatedSortOrder}&sort_type=${sortType}`
    const { slug } = selectedMeeting;
    dispatch(getOrderDetails(slug, invitedMeetingToken, serachParams));
  }

  const generatePrint = () => {
    const printElement = document.getElementById('DeliveryOrderDetailsSidebar');
    const printElementClone = printElement?.cloneNode(true) as HTMLElement;
    const filters = printElementClone?.querySelector('#delivery-orders-filters');
    filters?.remove();
    const table = printElementClone?.querySelector('#menu-detail-page-order-summary-table') as HTMLElement;
    table.style.height = 'unset';
    return printElementClone as Element;
  };

  const getFilteredOrders = () => {
    if (showGuestOrders && showMyOrders) {
      return [...userOwnOrders, ...guestOrders];
    } else if (showMyOrders) {
      return userOwnOrders;
    } else if (showGuestOrders) {
      return guestOrders;
    } else {
      return order?.delivery_orders_details?.orders || [];
    };
  };

  const EditDeliveryInstructions = () => {
    setDeliveryEditableLoader(true)
    dispatch(updateSelfSignUpDeliveryDetails(selectedMeeting?.slug, deliveryInstructionsEdited, DeliveryDetailsLoaderHandler))
  }

  const DeliveryDetailsLoaderHandler = () => {
    setDeliveryEditableLoader(false)
    setDeliveryEditable(false)
  }

  const deleteSucessHandler = () => {
    closeSidebar()
    setDeliveryCancelPopUp(false)

    window.location.replace('/menu')
  }

  const cancelCartDelivery = () => {
    const { slug } = selectedMeeting;
    dispatch(cancelCartOrder && cancelCartOrder(slug, deliveryCancelReason, deleteSucessHandler))
  }

  const deliveryCancelHandler = (e: any) => {
    setDeliveryCancelReason(e.target.value)
  }

  const filterHandler = (filterType: any) => {
    if (filterType === "showMyOrders") {
      setShowMyOrders(false)
    }
    else if (filterType === "showUnordered") {
      setShowUnordered(false)
    }
    else if (filterType === "showDeleted") {
      setShowDeleted(false)
    }
    else if (filterType === "showGuestOrders") {
      setShowGuestOrders(false)
    }
  }

  const columns: TableColumn[] = [
    { label: 'User', columnName: 'user', sortable: loggedInUserCompany?.self_signup === true ? false : true, className: 'border-0' },
    { label: 'Item', columnName: 'item', sortable: loggedInUserCompany?.self_signup === true ? false : true, className: 'border-0' },
    { label: 'Qty', className: 'cursor-default border-0', style: { width: 65 } },
    { label: 'Dish Size', hidden: isAdminBuffetDelivery ? false : true, className: 'cursor-default border-0' },
    { label: 'Serves', hidden: true, className: 'cursor-default border-0', style: { width: 60 } },
    { label: 'Price', hidden: isAdminBuffetDelivery ? true : false, className: 'cursor-default border-0' },
    { label: 'Total', hidden: isAdminBuffetDelivery ? false : true, className: 'cursor-default border-0', style: { width: 90 } },
    { label: 'Group', hidden: Boolean(!enable_grouping_orders && orders?.filter((order: any) => order?.status === OrderStatus.Active)[0].group), className: 'cursor-default border-0', style: { width: 70 } },
    { label: 'Status', columnName: 'status', sortable: loggedInUserCompany?.self_signup === true ? false : true, className: 'border-0', style: { width: 90 } },
    { label: 'Action', hidden: (!isSdMeetingCurrentlyOn && isPastDelivery) || (isSdPastDelivery && isPastDelivery) || (loggedInUserCompany?.self_signup === true), className: 'text-center cursor-default border-0', style: { width: 95 } }
  ];
  const filters = [
    ...(user.isCompanyAdmin ? [
      { label: 'My Orders', id: 'showMyOrders', disabled: userActiveOrdersQuantity === 0, total: userActiveOrdersQuantity, checked: showMyOrders },
      { label: 'Not Ordered', id: 'showUnordered', disabled: unorderedQuantity === 0, total: unorderedQuantity, checked: showUnordered },
      { label: 'Guest Orders', id: 'showGuestOrders', disabled: guestOrdersQuantity === 0, total: guestOrdersQuantity, checked: showGuestOrders },
    ] : []),
    { label: 'Cancelled Orders', id: 'showDeleted', disabled: !cancelled_total_quantity || cancelled_total_quantity === 0, total: cancelled_total_quantity, checked: showDeleted },
  ]
  // let OrderSummary;
  let OrderDetails;
  let ordersList = getFilteredOrders();


  let singleRowStringifiedData = ``;
  const appendData = (newData: string | number) => {
    singleRowStringifiedData += newData
  }

  // const cart_cutoff = dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date())
  if (order_summary && (loggedInUserCompany.self_signup === false)) {
    OrderDetails =
      <tbody className='bg-white'>
        {(ordersList || [])?.map((order, index) => {
          const isActiveOrder = order?.status === OrderStatus.Active;
          const isCancelledOrder = order?.status === OrderStatus.Cancelled;
          const isUnorderedOrder = order?.status === OrderStatus.Unordered;
          if (order) {
            singleRowStringifiedData = ``;
            const showUserAndCompanyPaidPrices = user?.isCompanyAdmin && !isUnorderedOrder && order?.user?.user_type !== UserType.ADMIN && user_copay === 1;
            const priceLabelValue = showUserAndCompanyPaidPrices ?
              Number((order?.user_price ?? 0) + (order?.company_price ?? 0)).toFixed(2) :
              (order?.price === null ? 'N/A' : Number(order?.price).toFixed(2));

            const row = <React.Fragment key={`${order?.id}` + index}>
              {(isActiveOrder) ||
                (showDeleted === true && isCancelledOrder) ||
                (showUnordered === true && isUnorderedOrder) ?
                <tr>
                  <>
                    {/* label: 'User' */}
                    <td>
                      <a href={'mailto:' + order?.user?.email} className="text-decoration-none text-gray-primary" >
                        <>
                          {appendData(order?.user?.name)}
                          <span className='text-capitalize long-table-text'> {order?.user?.name}</span>
                        </>
                      </a>
                    </td>
                    {/* label: 'Item' */}
                    <td className={`fooditem-text-capitalize long-table-text ${isCancelledOrder ? 'cancelled-affect ' : ''}`}>
                      <>
                        {appendData(order?.fooditem?.name ?? 'N/A')}
                        {order?.fooditem ? order?.fooditem.name : 'N/A'}
                      </>
                      <br />
                      {order?.remarks &&
                        <p className='m-0'>
                          <>
                            {appendData(`Remarks: ${order?.remarks}`)}
                            <b style={{ fontWeight: 600 }} className='text-capitalize'>Remarks: </b>
                            <span className='text-muted text-wrap long-table-text'>
                              {order?.remarks}
                            </span>
                          </>
                        </p>
                      }
                      {order?.selected_options &&
                        <p className='m-0'>
                          <>
                            {appendData(`Options: ${order?.selected_options}`)}
                            <b style={{ fontWeight: 600 }} className='text-capitalize'>Options: </b>
                            <span className='text-muted text-wrap'>
                              {order?.selected_options}
                            </span>
                          </>
                        </p>
                      }
                       {order?.utensils &&
                        <p className='m-0'>
                          <b style={{ fontWeight: 600 }} className='text-capitalize'>Utensils:  </b>
                          <span style={{ fontSize: 13 }} className='text-capitalize'>Added</span>
                        </p>
                      }
                    </td>
                    {/* label: 'Qty' */}
                    <td style={{ width: 65 }} className={`text-nowrap ${isCancelledOrder ? 'cancelled-affect' : ''}`}>
                      <>
                        {appendData(order?.quantity ?? 'N/A')}
                        {order?.quantity ? order?.quantity : 'N/A'}
                      </>
                    </td>
                    {/* Buffet Dishsizes and Serve Count */}
                    {isAdminBuffetDelivery ?
                      <>
                        {/* label: 'Dish Size' */}
                        <td className='text-nowrap'>
                          <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
                            <>
                              {appendData(order?.fooditem?.dishsize ?? 'N/A')}
                              {order?.fooditem ? order?.fooditem?.dishsize : 'N/A'}
                            </>
                          </span>
                          <br />
                          <b>Serves : </b>
                          <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
                            <>
                              {appendData(order?.fooditem?.serve_count ?? 'N/A')}
                              {order?.fooditem ? order?.fooditem.serve_count : 'N/A'}
                            </>
                          </span>
                        </td>
                        {/* label: 'Serve Count' */}
                        {/* <td className='text-nowrap' style={{ width: 60 }}>
                          <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
                            <>
                              {appendData(order?.fooditem?.serve_count ?? 'N/A')}
                              {order?.fooditem ? order?.fooditem.serve_count : 'N/A'}
                            </>
                          </span>
                        </td> */}
                      </>
                      : null
                    }
                    {/* label: 'Price' */}
                    {(user.isCompanyAdmin && !isAdminBuffetDelivery) ?
                      <td className={`${isCancelledOrder ? 'cancelled-affect' : ''} `}>
                        <>
                          {appendData(priceLabelValue)}
                          ${priceLabelValue ? Number(priceLabelValue)?.toFixed(2) : '00.00'}
                        </>
                        {showUserAndCompanyPaidPrices &&
                          <>
                            {appendData(`User paid : $${order?.user_price} Company paid : $${order?.company_price}`)}
                            <p className='text-muted mb-0'>User paid : ${order?.user_price}</p>
                            <p className='text-muted mb-0'>Company paid : ${order?.company_price}</p>
                          </>
                        }
                        <br />
                      </td>
                      :
                      (!isAdminBuffetDelivery && total_price !== 0) ?
                        <td>
                          <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
                            <>
                              {appendData(order?.user_price !== null ? '$' + Number(order?.user_price).toFixed(2) : 'N/A')}
                              {order?.user_price !== null ? '$' + Number(order?.user_price).toFixed(2) : 'N/A'}
                            </>
                          </span>
                        </td> :
                        <>  
                          {!user.isCompanyAdmin && <td><>
                            {appendData(order?.user_price !== null ? '$' + Number(order?.user_price).toFixed(2) : 'N/A')}
                            {order?.user_price !== null ? '$' + Number(order?.user_price).toFixed(2) : 'N/A'}
                          </></td>
                          }
                        </>
                    }
                    {/* label: 'Total' */}
                    {meeting_style === 'buffet' ?
                      <td style={{ width: 90 }}>
                        <span className={isCancelledOrder ? 'cancelled-affect' : ''}>
                          <>
                            {appendData(order?.fooditem ? '$' + Number(parseFloat(`${order?.fooditem.total_price}`)).toFixed(2) : 'N/A')}
                            {order?.fooditem ? '$' + Number(parseFloat(`${order?.fooditem.total_price}`)).toFixed(2) : 'N/A'}
                          </>
                        </span>
                      </td> : null}
                    {/* label: 'Group' */}
                    {enable_grouping_orders && order?.group ?
                      <td className='padding-left-15 group-overflow'>
                        <>
                          {appendData(order?.group)}
                          {order?.group}
                        </>
                      </td>
                      :
                      <td>
                        {isUnorderedOrder && orders?.filter((order) => isActiveOrder)[0]?.group ? 'N/A' : ''}
                      </td>
                    }
                    {/* label: 'Status' */}
                    {appendData(isUnorderedOrder ? 'Not Ordered' : order?.status)}
                    <td style={{ width: 90 }} className='text-nowrap'>
                      <span className={`badge badge-pill text-capitalize badge-${isActiveOrder ? 'success' : (isCancelledOrder ? 'danger' : 'warning')}`}>
                        {isUnorderedOrder ? 'Not Ordered' : order?.status}
                      </span>
                    </td>
                    {/* label: 'Action' */}
                    {(isSdPastDelivery && isPastDelivery) || (!isSdMeetingCurrentlyOn && isPastDelivery) ? null :
                      <>
                        {(isCancelledOrder || isUnorderedOrder || (isSdMeetingCurrentlyOn && !bothSchedulersActive && order?.same_day === false)) ?
                          <td></td> :
                          <td style={{ width: 95 }} className='text-nowrap'>
                            <div className='text-nowrap'>
                              {fromMenuDetailPopup &&
                                <button className='btn btn-light mr-2' onClick={() => updateOrder?.(order?.id!)}>
                                  <i className='fas fa-edit text-primary' aria-hidden='true'></i>
                                </button>}
                              <button
                                className='btn btn-light'
                                style={{ height: 40, width: 40 }}
                                disabled={orderRef?.current?.cancel_orders_loading[cancel_order_loading_key_prefix + order?.id]}
                                onClick={() => cancel_Order(order?.id!)}
                              >
                                {orderRef?.current?.cancel_orders_loading[cancel_order_loading_key_prefix + order?.id] ?
                                  <span className='text-danger cstm-remove-spinner float-right'>
                                    <Spinner spinnerSizeClass='spinner-border-sm' />
                                  </span> :
                                  <i className='far fa-trash-alt text-danger float-right text-right '></i>
                                }
                              </button>
                            </div>
                          </td>
                        }
                      </>
                    }
                  </>
                </tr> : null
              }
            </React.Fragment>
            return orderFilterSearchValue ?
              (singleRowStringifiedData?.toLowerCase().includes(orderFilterSearchValue.toLowerCase()) ?
                row : null) :
              row
          } else { // When Order is not Present (NULL)
            return null;
          }
        })
        }
      </tbody>
  } else if (selfSignUpOrder) {
    OrderDetails =
      <tbody className='bg-white'>
        {(selfSignUpOrder.orders || [])?.map((order, index: number) => {
          if (order) {
            singleRowStringifiedData = ``;
            const row = <React.Fragment key={`${order?.id}` + index}>
              {
                <tr>
                  <>
                    {/* label: 'User' */}
                    <td>
                      <>
                        {appendData(selfSignUpOrder?.user.delivery_name)}
                        <span className='text-capitalize long-table-text'> {selfSignUpOrder?.user.delivery_name}</span>
                      </>
                    </td>
                    {/* label: 'Item' */}
                    <td className={`fooditem-text-capitalize long-table-text`}>
                      <>
                        {appendData(order?.fooditem_name ?? 'N/A')}
                        {order?.fooditem_name ? order?.fooditem_name : 'N/A'}
                      </>
                      <br />
                      {order?.options &&
                        <p className='m-0'>
                          <>
                            {appendData(`Options: ${order?.options}`)}
                            <b style={{ fontWeight: 600 }} className='text-capitalize'>Options: </b>
                            <span className='text-muted text-wrap'>
                              {order?.options?.map((option: any, idx: number) => {
                                return <span key={idx} className='px-1'><br />{option?.description} </span>
                              })}
                            </span>
                          </>
                        </p>
                      }
                       {order?.utensils &&
                        <p className='m-0'>
                          <b style={{ fontWeight: 600 }} className='text-capitalize'>Utensils: </b>
                          <span style={{ fontSize: 13 }} className='text-capitalize'>Added</span>
                        </p>
                      }
                    </td>
                    {/* label: 'Qty' */}
                    <td style={{ width: 65 }} className={`text-nowrap`}>
                      <>
                        {appendData(order?.quantity ?? 'N/A')}
                        {order?.quantity ? order?.quantity : 'N/A'}
                      </>
                    </td>
                    {/* Buffet Dishsizes and Serve Count */}
                    {/* label: 'Price' */}
                    <td>
                      <span>
                        <>
                          {appendData(order?.total_price !== null ? '$' + Number(order?.total_price).toFixed(2) : 'N/A')}
                          {order?.total_price !== null ? '$' + Number(order?.total_price).toFixed(2) : 'N/A'}
                        </>
                      </span>
                    </td>
                    {/* label: 'Total' */}
                    {/* label: 'Group' */}
                    {order?.group ?
                      <td className='padding-left-15 group-overflow'>
                        <>
                          {appendData(order?.group)}
                          {order?.group}
                        </>
                      </td>
                      :
                      <td className='padding-left-15 group-overflow'>
                        N/A
                      </td>
                    }
                    {/* label: 'Status' */}
                    <td style={{ width: 90 }} className='text-nowrap'>
                      <span className={`badge badge-pill text-capitalize badge-success`}>
                        Active
                      </span>
                    </td>
                    {/* label: 'Action' */}
                  </>
                </tr>
              }
            </React.Fragment>
            return orderFilterSearchValue ?
              (singleRowStringifiedData.toLowerCase().includes(orderFilterSearchValue.toLowerCase()) ?
                row : null) :
              row
          } else { // When Order is not Present (NULL)
            return null;
          }
        })
        }
      </tbody>
  }

  return (
    <>
      {wholePageLoading ? <DeliveryOrderDetailsLoading closeSidebar={closeSidebar} /> :
        // Delivery Details Header and Filters Section
        <section id='menu-detail-page-order-summary' style={{ color: 'black' }}>
          {/* Sidebar Header */}
          <div className='px-3 px-md-4 bg-white d-flex justify-content-between align-items-center border-bottom' style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <h4 className='text-capitalize m-0 cursor-default fw-600 fs-sm-16' style={{ color: 'black' }}>
              {meeting_name || selectedMeeting?.runningmenu_name}
              <span style={{ fontSize: 12 }} className='badge badge-pill badge-secondary ml-2 text-capitalize'>
                {meeting_type}
              </span>
            </h4>
            <i onClick={() => closeSidebar?.()} className='cursor-pointer p-1 m-1 fas fa-times'></i>
          </div>
          {/* Order Details Section*/}
          <div className='px-3 px-md-4'>
            {/* Main Header For Order Details Section*/}
            {/* Check for Self-SignUp */}
            {(loggedInUserCompany?.self_signup === false) ? <div className='d-flex justify-content-between py-2'>
              <h5
                className='m-0 align-self-start py-1'
                style={{ fontSize: 22 }}>
                Order Details
              </h5>
              <div className='d-flex align-items-center align-self-end py-1'>
                <button
                  type='button'
                  disabled={detailsEmail}
                  onClick={sendEmail}
                  style={{ minWidth: 88, height: 34, border: '1px solid #157FFC' }}
                  className='btn btn-outline-primary py-1 mx-1 d-flex align-items-center'
                >
                  <i className='fas fa-envelope mr-2'></i>
                  <span className='flex-grow-1 text-center'>
                    {detailsEmail === true ?
                      <Spinner spinnerSizeClass='spinner-border-sm' /> :
                      i18n && i18n?.t('Email')
                    }
                  </span>
                </button>
                <ReactToPrint
                  trigger={() =>
                    <button
                      type='button'
                      onClick={() => window.print?.()}
                      className='btn btn-outline-primary mx-1 py-1'
                      style={{ minWidth: 88, border: '1px solid #157FFC' }}
                    >
                      <i className='fas fa-print mr-2'></i> {i18n && i18n?.t('Print')}
                    </button>}
                  //@ts-ignore
                  content={generatePrint}
                />
              </div>
            </div>
              :
              <div className='d-flex justify-content-between pt-4 row'>
                <div className='d-flex col-md-4 col-lg-4'>
                  <span className="icons-wrapper">
                    <img src="/imgs/SVGs/calendar.svg" alt="Delivery-Date" className='svg-dimensions-md'/>
                  </span>
                  <div className='pl-2 pl-md-3 pb-1 text-truncate d-flex align-items-center justify-content-between d-md-block w-100'>
                    {selfSignUpOrder?.delivery_at && <p className='fs-18 fw-600 text-gray-600 m-0 text-truncate view-details-headings'>
                      {getZonedTime(selfSignUpOrder?.delivery_at,'EEEE MMMM d, hh:mma, zzzz', selfSignUpOrder?.time_zone, 'insertAtInFormat')}
                    </p>
                    }
                    {/* <span className='badge-outline-primary cursor-pointer' title='Cutoff Description Tooltip' >
                      {dayjs(selfSignUpOrder?.cutoff_at).toNow(true)}
                      </span> */}
                    {dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) ?
                      <span data-tip
                        data-for='delivery-cutoff' className='badge-outline-primary cursor-pointer'>
                        Cutoff {`in ${dayjs(selfSignUpOrder?.cutoff_at).toNow(true)}`}
                      </span> : <span data-tip
                        data-for='delivery-cutoff' className='badge-outline-danger cursor-pointer'>Cutoff {`was ${dayjs(selfSignUpOrder?.cutoff_at).fromNow()}`}</span>
                    }
                    {/* <span className='badge-outline-danger cursor-pointer'>{dayjs(selfSignUpOrder?.cutoff_at).fromNow()}</span> */}
                    {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                      <ReactTooltip place='right' type='dark' id='delivery-cutoff' effect='solid' className='react-tooltip' >
                        <span>
                          This is the time until which you may cancel your order?.After this time<br />
                          the order is sent to the restaurant for preparation.<br />
                          Orders cannot be canceled after this time.
                        </span>
                      </ReactTooltip>
                    }
                  </div>
                </div>
                <div className='d-flex col-md-5 col-lg-5 mt-2 mt-md-0'>
                  <span className="icons-wrapper ml-0 ml-md-4">
                    <img src="/imgs/SVGs/location-icon.svg" alt="Delivery-Date" className='svg-dimensions-md'/>
                  </span>
                  <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className='pl-2 pl-md-3 pb-1 text-truncate address-details'>
                      <p className='fs-18 fw-600 text-gray-600 m-0 text-truncate view-details-headings' style={{maxWidth: '300px'}}>{selfSignUpOrder?.delivery_address}</p>
                      <p className='fs-16 m-0 text-gray-600 fw-normal fs-sm-11'>{selfSignUpOrder?.meeting_name}</p>
                    </div>
                    <div className="d-block d-md-none">
                      {(dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date())) ?
                        <button
                          className='btn btn-sm btn-danger py-1 cancel-delivery-btn'
                          disabled={dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) ? false : true}
                          onClick={() => setDeliveryCancelPopUp(true)}
                        // title='Sorry, unable to cancel delivery after cutoff.'
                        >
                          {/* <i className="far fa-calendar-times mx-2"></i> */}
                          <span className='fw-600' style={{ fontSize: '11px' }}>Cancel Delivery</span>
                        </button>
                        :
                        <p
                          data-tip
                          data-for='cancel-delivery'
                          className='btn btn-sm btn-danger py-1 cancel-delivery-btn'
                          style={{ opacity: '0.5' }}
                        >
                          {/* <i className="far fa-calendar-times mx-2"></i> */}
                          <span className='fw-600' style={{ fontSize: '11px' }}>Cancel Delivery</span>
                        </p>
                      }
                      {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                        <ReactTooltip place='top' type='dark' id='cancel-delivery' effect='solid' className='react-tooltip'>
                          <span>Sorry, unable to cancel delivery after cutoff.</span>
                        </ReactTooltip>
                      }
                    </div>
                  </div>
                </div>
                <div className=' py-0 py-md-1 col-md-3 col-lg-3 d-flex  align-items-center justify-content-end '>
                  {(dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date())) ?
                    <button
                      className='btn btn-sm btn-danger py-2 cancel-delivery-btn d-none d-md-block ml-auto'
                      disabled={dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) ? false : true}
                      onClick={() => setDeliveryCancelPopUp(true)}
                    // title='Sorry, unable to cancel delivery after cutoff.'
                    >
                      <i className="far fa-calendar-times mx-2"></i>
                      <span className='ps-2 fw-600'>Cancel Delivery</span>
                    </button>
                    :
                    <p
                      data-tip
                      data-for='cancel-delivery'
                      className='btn btn-sm btn-danger py-2 cancel-delivery-btn ml-auto'
                      style={{ opacity: '0.5' }}
                    >
                      <i className="far fa-calendar-times mx-2"></i>
                      <span className='ps-2 fw-600'>Cancel Delivery</span>
                    </p>
                  }
                  {window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                    <ReactTooltip place='top' type='dark' id='cancel-delivery' effect='solid' className='react-tooltip'>
                      <span>Sorry, unable to cancel delivery after cutoff.</span>
                    </ReactTooltip>
                  }
                </div>
              </div>
            }
            {/* New Design View details */}
            {/* Delivery Restaurant Address and Runningmenu Fields */}
            {/* Check for Self-SignUp */}
            {(loggedInUserCompany.self_signup === false) ? <div className='row'>
              <div className='col-12 col-md-3 my-1 d-flex flex-column justify-content-between'>
                <p style={{ color: '#646464' }} className='d-flex'>
                  <i className='fas fa-map-marker-alt mr-2 mt-1' style={{ fontSize: 20 }}></i>
                  <span>{formatted_address}</span>
                </p>
                <p style={{ color: '#646464' }} className='d-flex'>
                  <i className='fas fa-store mr-2 mt-1'></i>
                  <span>{restaurant_name}</span>
                </p>
              </div>
              <div className='col-12 col-md-9 my-1 d-flex justify-content-md-end' style={{ overflow: 'auto' }}>
                <div>
                  <div className='d-flex'>
                    <div className='px-3 text-nowrap'>
                      <p style={orderDetailHeading}>Delivery Time</p>
                      {order_summary ?
                        ordered_at && <p className='mb-1'>{getZonedTime(ordered_at,'E MMMM d, hh:mma, zzzz', time_zone, 'insertAtInFormat')}</p> : ''
                      }
                    </div>
                    {user.isCompanyAdmin ?
                      <>
                        <div className='px-1 text-nowrap'>
                          <p style={orderDetailHeading} >Style</p>
                          <p className='text-capitalize mb-1'>{meeting_style}</p>
                        </div>
                        <div className='px-1 text-nowrap'>
                          <p style={orderDetailHeading} >Orders</p>
                          <p className='mb-1'>{total_quantity ?? 0}</p>
                        </div>
                        <div className='px-3 text-nowrap'>
                          <p style={orderDetailHeading} >
                            {meeting_style === 'individual' ? 'Avg. Per Meal' : 'Price Per Head'}
                          </p>
                          <p className='mb-1'>
                            {meeting_style === 'individual' ? `$${avg_per_meal ? Number(avg_per_meal)?.toFixed(2) : '00.00'}` : `${price_per_head ? Number(price_per_head)?.toFixed(2) : '00.00'}`}
                          </p>
                        </div>
                        <>
                          {!isEmpty(delivery_fee) &&
                            <div className='px-3 text-nowrap'>
                              <p style={orderDetailHeading} >Delivery Fee</p>
                              <p className='mb-1'>${delivery_fee ? Number(delivery_fee)?.toFixed(2) : '00.00'}</p>
                            </div>
                          }
                        </>
                      </> :
                      null

                    }
                    <>
                      {!isEmpty(invoice_discount) &&
                        <div className='px-1 text-nowrap'>
                          <p style={orderDetailHeading} >Discount</p>
                          <p className='mb-1'>${invoice_discount ? Number(invoice_discount)?.toFixed(2) : '00.00'}</p>
                        </div>
                      }
                    </>
                    <div className='px-3 text-nowrap'>
                      <p style={orderDetailHeading} >Total</p>
                      <p className='mb-1' style={{ color: '#157FFC', fontSize: 24, fontWeight: 600 }}> ${total_price ? Number(total_price)?.toFixed(2) : '00.00'}</p>
                    </div>
                  </div>
                  {user.isCompanyAdmin ?
                    <div className='d-flex mb-2'>
                      {runningmenu_fields?.map((field) =>
                        <div key={field?.value + field?.name} className='px-3 text-nowrapm-1'>
                          <p style={orderDetailHeading} >{field?.name}</p>
                          <p className='mb-1'>{field?.value}</p>
                        </div>
                      )}
                    </div> :
                    null
                  }
                </div>
              </div>
            </div>
              :
              <div className='pt-3'>
                <div className='mobile-view-details'>
                  <div className="row w-100 px-0">
                    <div className="col-12 px-0 d-flex">
                      <div className="col-2 text-nowrap">
                        <p className='delivery-details-heading-label'>Person Name</p>
                        <p className='delivery-details-heading-value'>{selfSignUpOrder?.user.delivery_name}</p>
                      </div>
                      <div className="col-2 text-nowrap">
                        <p className='delivery-details-heading-label'>Phone Number</p>
                        <p className='delivery-details-heading-value'>{selfSignUpOrder?.user.phone_number}</p>
                      </div>
                      <div
                        className="col-8 text-nowrap text-truncate pb-1"
                      >
                        <p className='delivery-details-heading-label d-flex w-100'>
                          Delivery Instructions
                          {dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) &&
                            <span className='d-flex justify-content-between w-100'>
                              {deliveryEditable ?
                                <span className='fs-16 fw-600 pl-3 cursor-pointer ml-auto' style={{ color: '#167FFB' }} onClick={EditDeliveryInstructions}>Done</span>
                                :
                                <span className='fs-16 fw-600 pl-3 cursor-pointer' style={{ color: '#167FFB' }} onClick={() => setDeliveryEditable(true)}>Edit</span>
                              }
                            </span>
                          }
                        </p>
                        {deliveryEditableLoader ? <Skeleton height={30} width={580} /> :
                          (deliveryEditable ?
                            <input type="text" className='form-control' style={{ backgroundColor: '#fafcfe' }} value={deliveryInstructionsEdited} onChange={(e) => { setDeliveryInstructionsEdited(e.target.value) }} />
                            :
                            <> {(selfSignUpOrder?.delivery_instructions && selfSignUpOrder?.delivery_instructions?.length > 60) ?
                              <p
                                data-tip
                                data-for='delivery-instructions'
                                className='delivery-details-heading-value text-truncate'>{selfSignUpOrder?.delivery_instructions}
                              </p>
                              :
                              <p
                                className='delivery-details-heading-value'
                              >
                                {selfSignUpOrder?.delivery_instructions}
                              </p>
                            }</>
                          )
                        }
                        {(selfSignUpOrder?.delivery_instructions && selfSignUpOrder?.delivery_instructions?.length > 60) && window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                          <ReactTooltip place='top' type='dark' id='delivery-instructions' effect='solid' className='react-tooltip w-50'>
                            <p className='text-wrap'>{selfSignUpOrder?.delivery_instructions}</p>
                          </ReactTooltip>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row w-100 px-0 mt-32 mb-32">
                    <div className="col-12 px-0 d-flex">
                      <div className="col-2 text-nowrap">
                        <p className='delivery-details-heading-label'>Items Subtotal</p>
                        <p className='delivery-details-heading-value'>${selfSignUpOrder?.items_subtotal ? Number(selfSignUpOrder?.items_subtotal)?.toFixed(2) : '00.00'}</p>
                      </div>
                      <div className="col-2 text-nowrap">
                        <p className='delivery-details-heading-label'> Sales Tax</p>
                        <p className='delivery-details-heading-value'>${selfSignUpOrder?.sales_tax ? Number(selfSignUpOrder.sales_tax)?.toFixed(2) : '00.00'}</p>
                      </div>
                      <div className="col-2 text-nowrap">
                        <p className='delivery-details-heading-label'>Delivery Fee</p>
                        <p className='delivery-details-heading-value'>${selfSignUpOrder?.delivery_cost ? Number(selfSignUpOrder.delivery_cost)?.toFixed(2) : '00.00'}</p>
                      </div>
                      <div className="col-2 text-nowrap">
                        <p className='delivery-details-heading-label'>Driver Tip</p>
                        <p className='delivery-details-heading-value'>${selfSignUpOrder?.driver_tip ? Number(selfSignUpOrder.driver_tip)?.toFixed(2) : '00.00'}</p>
                      </div>
                      <div className="col-3 text-nowrap">
                        <p className='delivery-details-heading-label'>Credit Card Processing Fee</p>
                        <p className='delivery-details-heading-value'>${selfSignUpOrder?.cc_processing_fee ? Number(selfSignUpOrder.cc_processing_fee)?.toFixed(2) : '00.00'}</p>
                      </div>
                      <div className="col-1 text-nowrap">
                        <p className='delivery-details-heading-label'>Total</p>
                        <h4 className='fw-600 text-grey-600 m-0 mt-1'>${selfSignUpOrder?.total ? Number(selfSignUpOrder.total)?.toFixed(2) : '00.00'}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mobile-filter-Carousel'>
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={carousel_responsive}
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    containerClass='carousel-container'
                    removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
                    dotListClass='marketplace-custom-dot-list-style'
                  >
                    <div className="row w-100 px-0">
                      <div className="col-12 px-0 d-flex">
                        <div className="col-6 text-nowrap">
                          <p className='delivery-details-heading-label'>Person Name</p>
                          <p className='delivery-details-heading-value'>{selfSignUpOrder?.user.delivery_name}</p>
                        </div>
                        <div className="col-6 text-nowrap">
                          <p className='delivery-details-heading-label'>Phone Number</p>
                          <p className='delivery-details-heading-value'>{selfSignUpOrder?.user.phone_number}</p>
                        </div>
                      </div>
                      <div className="col-12 text-nowrap text-truncate pt-2" >
                        <p className='delivery-details-heading-label d-flex w-100'>
                          Delivery Instructions
                          {dayjs(selfSignUpOrder?.cutoff_at).isAfter(new Date()) &&
                            <span className='d-flex justify-content-between w-100'>
                              {deliveryEditable ?
                                <span className='fs-16 fw-600 pl-3 cursor-pointer ml-auto' style={{ color: '#167FFB' }} onClick={EditDeliveryInstructions}>Done</span>
                                :
                                <span className='fs-16 fw-600 pl-3 cursor-pointer' style={{ color: '#167FFB' }} onClick={() => setDeliveryEditable(true)}>Edit</span>
                              }
                            </span>
                          }
                          {/* <span className='d-flex justify-content-between w-100' >
                          <span className='fs-16 fw-600 pl-3 cursor-pointer' style={{ color: '#167FFB' }} onClick={() => this.setState({ deliveryEditable: true })}>Edit</span>
                          <span className='fs-16 fw-600 pl-3 cursor-pointer' style={{ color: '#167FFB' }} onClick={this.EditDeliveryInstructions}>Done</span>
                        </span> */}
                        </p>
                        {deliveryEditableLoader ? <Skeleton height={30} width={580} /> :
                          (deliveryEditable ?
                            <input type="text" className='form-control' style={{ backgroundColor: '#fafcfe' }} value={deliveryInstructionsEdited} onChange={(e) => { setDeliveryInstructionsEdited(e.target.value) }} />
                            :
                            <> {(selfSignUpOrder?.delivery_instructions && selfSignUpOrder?.delivery_instructions?.length > 60) ?
                              <p
                                data-tip
                                data-for='delivery-instructions'
                                className='delivery-details-heading-value text-truncate'>{selfSignUpOrder?.delivery_instructions}
                              </p>
                              :
                              <p
                                className='delivery-details-heading-value'
                              >
                                {selfSignUpOrder?.delivery_instructions}
                              </p>
                            }</>
                          )
                        }
                        {(selfSignUpOrder?.delivery_instructions && selfSignUpOrder?.delivery_instructions?.length > 60) && window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                          <ReactTooltip place='top' type='dark' id='delivery-instructions' effect='solid' className='react-tooltip w-50'>
                            <p className='text-wrap'>{selfSignUpOrder?.delivery_instructions}</p>
                          </ReactTooltip>
                        }
                      </div>
                    </div>
                    <div className="row px-0">
                      <div className="col-12 px-0 d-flex">
                        <div className="col-3 text-nowrap">
                          <p className='delivery-details-heading-label'>Items Subtotal</p>
                          <p className='delivery-details-heading-value'>${selfSignUpOrder?.items_subtotal ? Number(selfSignUpOrder.items_subtotal)?.toFixed(2) : '00.00'}</p>
                        </div>
                        <div className="col-3 text-nowrap">
                          <p className='delivery-details-heading-label'> Sales Tax</p>
                          <p className='delivery-details-heading-value'>${selfSignUpOrder?.sales_tax ? Number(selfSignUpOrder.sales_tax)?.toFixed(2) : '00.00'}</p>
                        </div>
                        <div className="col-3 text-nowrap">
                          <p className='delivery-details-heading-label'>Delivery Fee</p>
                          <p className='delivery-details-heading-value'>${selfSignUpOrder?.delivery_cost ? Number(selfSignUpOrder.delivery_cost)?.toFixed(2) : '00.00'}</p>
                        </div>
                        <div className="col-3 text-nowrap">
                          <p className='delivery-details-heading-label'>Driver Tip</p>
                          <p className='delivery-details-heading-value'>${selfSignUpOrder?.driver_tip ? Number(selfSignUpOrder.driver_tip)?.toFixed(2) : '00.00'}</p>
                        </div>
                      </div>
                      <div className="col-12 px-0 d-flex pt-2">
                        <div className="col-6 text-nowrap">
                          <p className='delivery-details-heading-label'>Credit Card Processing Fee</p>
                          <p className='delivery-details-heading-value'>${selfSignUpOrder?.cc_processing_fee ? Number(selfSignUpOrder.cc_processing_fee)?.toFixed(2) : '00.00'}</p>
                        </div>
                        <div className="col-6 text-nowrap">
                          <p className='delivery-details-heading-label'>Total</p>
                          <h4 className='fw-600 text-grey-600 m-0 mt-1 fs-sm-14'>${selfSignUpOrder?.total ? Number(selfSignUpOrder.total)?.toFixed(2) : '00.00'}</h4>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            }
          </div>
          <hr className='mt-1' />
          {/* Delivery Orders Filters*/}
          <div className="px-3 px-md-4">
            <div id='delivery-orders-filters' className='d-flex align-items-center justify-content-between pt-2 pb-3'>
              {(loggedInUserCompany?.self_signup === true) && <h3 className='m-0 fw-600 fs-sm-16' style={{ fontSize: 22 }}>Items List</h3>}
              {/* Orders Status Filters */}
              {/* Filter starts here comment */}
              {(loggedInUserCompany?.self_signup === false) && <div className='d-flex align-items-center my-1 align-self-start flex-wrap'>
                <div className='align-self-start align-self-md-center' style={{ 'position': 'relative' }}>
                  <button
                    style={{ backgroundColor: '#157FFC' }}
                    type='button'
                    // data-toggle='dropdown'
                    // collapse
                    id='ordersFiltersDropdown'
                    className='btn btn-primary border-0 text-nowrap'
                    onClick={() => setToggleDropdown(!toggleDropdown)}
                  >
                    <i className='fas fa-filter mr-2' aria-hidden='true'></i>
                    Filters
                    <i className={`ml-3 fas fa-chevron-down`} aria-hidden='true'></i>
                  </button>
                  {toggleDropdown && <div className='item-list-dropDown m-0 p-2' aria-labelledby='ordersFiltersDropdown'>
                    {filters?.map((filter, index) =>
                      <div
                        key={filter?.id}
                        className={`d-flex align-items-center ${filter.disabled ? 'disabled-opacity' : ''} ${index === filters.length - 1 ? '' : 'pb-2'}`}
                      >
                        <input
                          type='checkbox'
                          className='cursor-pointer'
                          id={filter.id}
                          disabled={filter.disabled}
                          onChange={() => ordersFilterHandler(filter.id as OrdersFilters)}
                          checked={filter.checked}
                        />
                        <label htmlFor={filter?.id} className='m-0 ml-2 cursor-pointer' >
                          {filter?.label}
                        </label>
                      </div>)
                    }
                  </div>}
                </div>
                <div className='px-md-3 d-none d-md-flex flex-wrap align-self-start' style={{ transition: 'all .2s' }}>
                  {filters?.filter(filter => filter.checked)?.map(appliedFilter =>
                    <span
                      key={appliedFilter?.id + appliedFilter?.label}
                      className='py-1 my-1 mx-2 px-2 cursor-default text-nowrap'
                      style={{ borderRadius: 20, border: '1px solid #157FFC', color: '#157FFC' }}
                    >
                      {appliedFilter?.label} ({appliedFilter?.total})
                      <i
                        className={`cursor-pointer ml-3 far fa-times-circle`}
                        //onClick={() => setState(prevState => ({ ...prevState, [appliedFilter.id]: false }))}
                        onClick={() => filterHandler(appliedFilter.id)}
                      ></i>
                    </span>
                  )}
                </div>
              </div>
              }

              {/* <div className='d-flex align-items-center my-1 align-self-start flex-wrap'>
                  <div className='align-self-start align-self-md-center'>
                    <button
                      style={{ backgroundColor: '#157FFC' }}
                      type='button'
                      data-toggle='dropdown'
                      id='ordersFiltersDropdown'
                      className='btn btn-primary border-0 text-nowrap'
                    >
                      <i className='fas fa-filter mr-2' aria-hidden='true'></i>
                      Filters
                      <i className={`ml-3 fas fa-chevron-down`} aria-hidden='true'></i>
                    </button>
                    <div className='dropdown-menu m-0 p-2' aria-labelledby='ordersFiltersDropdown'>
                      {filters.map((filter, index) =>
                        <div
                          key={filter.id}
                          className={`d-flex align-items-center ${filter.disabled ? 'disabled-opacity' : ''} ${index === filters.length - 1 ? '' : 'pb-2'}`}
                        >
                          <input
                            type='checkbox'
                            className='cursor-pointer'
                            id={filter.id}
                            disabled={filter.disabled}
                            onChange={() => this.ordersFilterHandler(filter.id as OrdersFilters)}
                            checked={filter.checked}
                          />
                          <label htmlFor={filter.id} className='m-0 ml-2 cursor-pointer' >
                            {filter.label}
                          </label>
                        </div>)
                      }
                    </div>
                  </div>
                  <div className='px-md-3 d-none d-md-flex flex-wrap align-self-start' style={{ transition: 'all .2s' }}>
                    {filters.filter(filter => filter.checked).map(appliedFilter =>
                      <span
                        key={appliedFilter.id + appliedFilter.label}
                        className='py-1 my-1 mx-2 px-2 cursor-default text-nowrap'
                        style={{ borderRadius: 20, border: '1px solid #157ffc', color: '#157ffc' }}
                      >
                        {appliedFilter.label} ({appliedFilter.total})
                        <i
                          className={`cursor-pointer ml-3 far fa-times-circle`}
                          onClick={() => this.setState(prevState => ({ ...prevState, [appliedFilter.id]: false }))}
                        ></i>
                      </span>
                    )}
                  </div>
                </div> */}
              {/* Search Filter */}
              <div className='d-flex bg-white px-1 pl-3 align-items-center align-self-start align-self-md-center overflow-hidden'
                style={{ border: '1px solid #dfdfe3', borderRadius: 20 }}
              >
                <span>
                  <i className='fas fa-search' style={{ color: '#999999' }}></i>
                </span>
                <input
                  type='text'
                  placeholder='Search'
                  style={{ color: '#999999' }}
                  onChange={({ target: { value } }) => setOrderFilterSearchValue(value)}
                  className='flex-grow-1 shadow-none form-control border-0 p-2'
                />
              </div>
            </div>
          </div>
        </section>
      }
      {/* Orders Table */}
      <section
        className='menu-detail-page-order-summary-table'
        id='menu-detail-page-order-summary-table'
        style={{ height: menuPageOrdersTableBodyHeight, overflow: 'auto' }}
      >
        <table className={`table table-hover border-0 ${(wholePageLoading || sortingLoading) ? 'disabled-opacity' : ''}`}>
          <TableHeader
            theadStyles={{ backgroundColor: '#fafafa', position: 'sticky', top: 0, border: 0 }}
            columns={columns}
            sortingColumn={sortType}
            handleSorting={handleSorting}
            inlineHeader
          />
          {(wholePageLoading || sortingLoading) ?
            // Loading In case of Sorting or When Order Data is Not Recieved
            <tbody>
              {ArrayOfLength(Math.ceil((+menuPageOrdersTableBodyHeight) / 65))?.map(index =>
                <tr key={index}>
                  {columns?.filter((col) => !col.hidden)?.map((col, index) =>
                    <td key={col?.label} style={{ minWidth: tableColumnsWidths[index] ?? 'unset', height: 63 }}>
                      <Skeleton width='100%' height={25} />
                    </td>
                  )}
                </tr>)
              }
            </tbody>
            : OrderDetails
          }
        </table>
      </section>
      <Modal
        isOpen={deliveryCancelPopUp}
        onRequestClose={() => setDeliveryCancelPopUp(false)}
        style={customStyles}
        contentLabel='Example Modal'>
        <div className='order-modal' id='orderModelBox'>
          <div className='modal-dialog' style={{ maxWidth: '100%' }}>
            <div className='modal-content' style={{ border: 'none' }}>
              <div className={`modal-header bg-white`} style={{ width: '690px' }}>
                <h5 className='modal-title' id='modal-title'>  {i18n && i18n?.t('Cancel Delivery')} </h5>
                <button type='button' onClick={() => setDeliveryCancelPopUp(false)} className='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>×</span></button>
              </div>
              <div id='mydiv' className='modal-body' style={{ width: '690px' }}>
                <div id='main' className='container'>
                  <div className='form-row'>
                    <div className='col-11 m-auto text-center'>
                      <span>
                        <h2 className='text-center'> {i18n && i18n?.t('Cancel Delivery.')}</h2>
                        <p className='text-muted'><span className='text-danger'>{selfSignUpOrder?.orders.length}  {i18n && i18n?.t('Orders')}</span>  {i18n && i18n?.t('Rewill be cancelled for this meeting.quired')}</p>
                      </span>
                    </div>
                  </div>
                  <form noValidate className='text-left'>
                    <div className='form-row'>
                      <div className='col-12 company-required-field p-3'>
                        <h6 className='orderform-section-name float-left '>  {i18n && i18n?.t('Reason for Cancellation')}</h6>
                        <h6 className='orderform-section-required muted float-right '> {i18n && i18n?.t('Required')}</h6>
                        <textarea className='form-control'
                          id='name'
                          name='cancelReason'
                          placeholder={i18n ? i18n?.t('Prefer different vendor, meeting rescheduled, etc') : ''}
                          onChange={deliveryCancelHandler}>
                        </textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-footer bg-white`} style={{ width: '100%' }}>
          <button
            type='submit'
            className='confirm-cancel-delivery ml-3 btn btn-primary delivery-btn-width'
            onClick={() => cancelCartDelivery()}
            disabled={deliveryCancelReason === ''}
          >
            {i18n && i18n?.t('Cancel Delivery')}
          </button>
        </div>
      </Modal>
      {/* Delete Order Popup */}
      <DeleteOrderConfirmationPopup
        orderDeletePopup={orderDeletePopup}
        updatePageOnOrderDelete={updateOrderData}
        handleCloseModal={handleCloseModal}
        fromMenuDetailPopup={fromMenuDetailPopup}
        selectedMeeting={selectedMeeting}
        invitedMeetingToken={invitedMeetingToken}
        orderId={orderId}
      />
    </>
  )
}

export default DeliveryOrderDetails