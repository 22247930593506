import axios from 'axios';
import { SET_CURRENT_USER, GET_ERRORS, SEND_FORGOT_PASSWORD_EMAIL, RESET_STATUS, GET_COMPANY_ADDRESSES, RESET_STATE, CART_AT_SERVER } from './types';
import { getError } from './errorActions';
import { AdminLoginPayload } from '../Pages/Login/AdminLogin';
import { GoogleAuthenticatedUser, LoginUserPayLoad } from '../Pages/Login/Login';
import { AppThunkAction } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Adminlogin, ForgotPasswordConfirmation, LoginUser, ResetPassword, UnlockUserAccount, User, UserHeaders } from '../Interfaces/PayloadsAndResponses/Auth';
import { RouteComponentProps } from 'react-router';
import { showToastNotification } from '../Utilities/showToastNotification';
import { PlaceCartOrderPayload } from '../Interfaces/PayloadsAndResponses/Cart';
import { placeCartOrder } from './cartActions';
import dayjs from 'dayjs';

// Login User Action
export const loginUser = (userData: LoginUserPayLoad | GoogleAuthenticatedUser, errorCallback: () => void, loginSuccessHandler?: (response: any) => void, cartOrderPayload?: PlaceCartOrderPayload): AppThunkAction => dispatch => {
  localStorage.removeItem?.('address');
  localStorage?.removeItem?.('address-placeId');

  axios
    .post('/auth/sign_in', userData)
    .then(res => {
      const headers = res.headers;
      const { data } = res.data as LoginUser ?? {};
      // const { googleData } = res.data as LoginUserGoogleResponse ?? {};

      const { user, company } = data ?? {};

      if (res?.data && res?.data?.name && res?.data?.email) {

        const fullName = res?.data?.name?.split(' ')
        const firstName = fullName[0]
        const lastName = firstName[1] ?? fullName[0]
        const googleSignUpinProgress = {
          first_name: firstName,
          last_name: lastName,
          email: res?.data?.email,
        }

        localStorage.setItem('signup-in-progress', JSON.stringify(googleSignUpinProgress));

        errorCallback()
      }

      try {
        const newHeaders: UserHeaders = {
          'access-token': '',
          client: '',
          expiry: '',
          'token-type': '',
          uid: '',
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'en/US'
        };
        Object.keys(headers).forEach(function (key) {
          switch (key) {
            case 'access-token':
            case 'client':
            case 'expiry':
            case 'token-type':
            case 'uid':
              newHeaders[key] = headers[key];
              break;
            default:
              break;
          }
        });
        if (res?.data && res?.data?.name && res?.data?.email) {
          const fullName = res?.data?.name?.split(' ')
          const firstName = fullName[0]
          const lastName = firstName[1] ?? fullName[0]
          const googleSignUpinProgress = {
            first_name: firstName,
            last_name: lastName,
            email: res?.data?.email,
          }

          localStorage.setItem('signup-in-progress', JSON.stringify(googleSignUpinProgress));
          errorCallback()
        } else {
          localStorage.setItem('headers', JSON.stringify(newHeaders));
          if (user?.first_name) {
            user.first_name = user.first_name?.trim();
          }
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('company', JSON.stringify(company));
        }
      } catch (e) {
        console.log('error', e);
      }
      // Set current User
      dispatch(setCurrentUser(user, headers as UserHeaders));
      // Send order data

      // const cartOrderspayloadWithAddressId = { ...cartOrderPayload, company: { addresses_active_attributes: [modifiedAddress] } }

      const placeCartOrderSuccessHandler = (res: any) => {
        if (res.slug) {
          window.location.href = `/menu/${dayjs(res.delivery_at).format('M-D-YYYY')}/${res?.slug}`
          loginSuccessHandler?.(res)
        }
      }

      const scriptDataString = JSON.stringify({
        user: {
          first_name: `${user?.first_name}`,
          email: `${user?.email}`,
          last_name: `${user?.last_name}`
        },
      });

      window.RF = JSON.parse(scriptDataString);

      if (cartOrderPayload) {
        dispatch(placeCartOrder?.(cartOrderPayload as PlaceCartOrderPayload, headers as UserHeaders, undefined, undefined, undefined, undefined, placeCartOrderSuccessHandler));
        localStorage?.removeItem?.('address');
      } else {
        loginSuccessHandler?.('fromMarketPlace')
      }
      // Remove address from local storage in case of sigin
      // localStorage?.removeItem?.('address');
    })
    .catch(err => {
      errorCallback();
      const error = err?.response?.data;
      const errMsg = 'Something went wrong';
      !error?.errors[0] && showToastNotification(errMsg, 'Oops!', true);
      dispatch(sendError(error?.errors[0]));
    });
};

export const adminlogin = (userData: AdminLoginPayload): AppThunkAction => dispatch => {
  localStorage?.removeItem?.('address');
  localStorage?.removeItem?.('address-placeId');

  axios
    .post('/auth/sign_in', userData)
    .then(res => {
      const headers = res.headers;
      const { data } = res.data as Adminlogin ?? {};
      const { user, company } = data ?? {};
      try {
        const newHeaders: UserHeaders = {
          'access-token': '',
          client: '',
          expiry: '',
          'token-type': '',
          uid: '',
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'en/US'
        };
        Object.keys(headers).forEach(function (key) {
          switch (key) {
            case 'access-token':
            case 'client':
            case 'expiry':
            case 'token-type':
            case 'uid':
              newHeaders[key] = headers[key];
              break;
            default:
              break;
          }
        });
        localStorage.setItem('headers', JSON.stringify(newHeaders));
        if (user?.first_name) {
          user.first_name = user.first_name?.trim();
        }
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('company', JSON.stringify(company));
      } catch (e) {
        console.log('error', e);
      }
      dispatch(setCurrentUser(user || {}, headers as UserHeaders));
    })
    .catch(err => {
      const error = err?.response?.data;
      dispatch(sendError(error.errors[0]));
    });
};
export const sendError = (error: any) => {
  console.log('i have reported', error)
  return {
    type: GET_ERRORS,
    payload: error
  };
};

export const setCurrentUser = (user: User | null, headers: UserHeaders | null) => {
  return {
    type: SET_CURRENT_USER,
    payload: {
      user: user,
      headers: headers,
    }
  };
};


// Log user out
export const logoutUser = (history?: RouteComponentProps['history']): AppThunkAction => dispatch => {
  axios
    .delete('/auth/sign_out',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      history && history.replace({ pathname: '/signin', state: undefined });
      // window.location.reload()
      console.log('Session Expired')
    })
    .catch(err =>
      dispatch(getError(err))
    );
  localStorage.clear();
  dispatch(setCurrentUser(null, null));
  // GET_COMPANY_ADDRESSES
  dispatch({
    type: GET_COMPANY_ADDRESSES,
    payload: null
  })
  dispatch({
    type: RESET_STATE
  })

};

export const clearOldCart = (): AppThunkAction => dispatch => {
  dispatch({
    type: CART_AT_SERVER,
    payload: {}
  });
};

// Send password resset link to user email.
export const resetPasswordLink = (email: string): AppThunkAction => dispatch => {

  axios
    .post(`/auth/password?email=${email}&redirect_url=${process.env.REACT_APP_REDIRECT_URL}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: SEND_FORGOT_PASSWORD_EMAIL,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(getError(err));
    });

};


// Send password resset link to user email.
export const resetPassword = (password: string, password_confirmation: string, accessToken: string, client: string, uid: string, successCallback: (message: string) => void): AppThunkAction => dispatch => {
  let headers = {
    'access-token': accessToken,
    'token-type': 'Bearer',
    'Content-Type': 'application/json',
    client: client,
    uid: uid,
  };
  axios
    .put(`/auth/password`,
      { password, password_confirmation }, // Request Body
      { headers } // Request Headers
    )
    .then(res => {
      const data = res.data as ResetPassword;
      successCallback?.(data.message);
      dispatch({
        type: RESET_STATUS,
        payload: data
      })
    })
    .catch(err => {
      console.error(err);
      successCallback?.('Oops!. An Unexpected Error Occurred');
      dispatch(getError(err));
    });
};

// https://admin-stage.chowmill.com/api/v1/auth/password/edit
export const forgotPasswordConfirmation = (token: string, history: RouteComponentProps['history'], successCallback: (msg: any) => void): AppThunkAction => dispatch => {
  axios
    .get(`/auth/password/edit?config=default&reset_password_token=${token}&redirect_url=${process.env.REACT_APP_REDIRECT_URL}`)
    .then(res => {
      const data = res.data as ForgotPasswordConfirmation;
      if (data.success) {
        showToastNotification(data.message, 'Success!');


        const redirectUrl = data.redirect_url;
        const [ , queryString] = redirectUrl.split('?');

        const queryParams = new URLSearchParams(queryString);
        const requiredKeys = new Set(['access-token', 'client', 'token']);
        const params = new URLSearchParams();

        queryParams.forEach((value, key) => {

          if (requiredKeys.has(key)) {
            params.append(key, value);
          } else {
            params.append(key, value);
          }
        });

        history.push({
          pathname: '/reset_password',
          search: params.toString(),
        });


      } else {
        showToastNotification(data.message, 'Oops!', !data.success);
        history && history.replace({ pathname: '/forgot_password', state: undefined });
      }
    })
    .catch(err => {
      const errMsg = 'Token is invalid';
      showToastNotification(errMsg, 'Oops!', true);
      history && history.replace({ pathname: '/signin', state: undefined });
      dispatch(getError(err));
    });
};

export const unlockUserAccount = (token: string, history: RouteComponentProps['history'], successCallback: (msg: any) => void): AppThunkAction => dispatch => {
  axios
    .get(`/auth/unlock?config=default&unlock_token=${token}`)
    .then(res => {
      const data = res.data as UnlockUserAccount;
      successCallback?.(data.message)
    })
    .catch(err => {
      const errMsg = 'Token is invalid';
      showToastNotification(errMsg, 'Oops!', true);
      history && history.replace({ pathname: '/signin', state: undefined });
      dispatch(getError(err));
    });
};
export const verifyEmailExternalLink = (token: string, successCallback: (msg: any) => void): AppThunkAction => dispatch => {
  axios
    .get(`/users/verify_email?verify_code=${token}`)
    .then(res => {
      const data = res.data as any;
      successCallback?.(data.message)
    })
    .catch(err => {
      dispatch(getError(err));
    });
};

export const getRedirectURL = (token: string, history: RouteComponentProps['history']) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  axios
    .get(`/shortened_urls/${token}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then((res) => {
      if (res?.data?.url) {
        window.location.href = res?.data?.url
      } else {
        history?.push('/signin')
      }
    })
    .catch(err => history?.push('/signin')
    );
};
